<template>
  <div class="h5-box">
    <div class="hardware-banner">
      <div class="hardware-banner-title">{{selectHardware.swiperList[0].title}}</div>
      <div class="hardware-banner-sub-title">{{selectHardware.swiperList[0].subTitle}}</div>
      <div class="hardware-banner-sub-img" v-if="refreshStyle" :style="`right:${selectHardware.swiperList[0].right}`">
        <img :style="`width:${selectHardware.swiperList[0].width};height:${selectHardware.swiperList[0].height}`" :src="selectHardware.swiperList[0].imgUrl" alt="">
      </div>
    </div>
    <!--    产品概述-->
    <ModuleWrap
      title="产品概述"
      bg-img="/img/hardware/bg01.png"
    >
      <div class="content-card" >
        <div v-if="typeof(selectHardware.info.desc) === 'string'">
          {{selectHardware.info.desc}}
        </div>
        <div v-else>
          <div v-for="(item,index) in selectHardware.info.desc" :key="index">{{item}}</div>
        </div>
      </div>
    </ModuleWrap>
    <!--  产品特点   -->
    <ModuleWrap
        title="产品特点"
        bg-col="#fff"
        v-show="selectHardware.characteristic"
    >
      <div class="hardware-box">
        <div
            class="hardware-item"
            v-for="(item) in selectHardware.characteristic"
            :key="item.name"
        >
          <img class="hardware-item-img" :src="item.icon" alt="">
          <div class="hardware-item-name" v-if="item.name" >{{item.name}}</div>
          <div class="hardware-item-desc" >{{item.desc}}</div>
        </div>
      </div>
    </ModuleWrap>

        <!--  规格参数   -->
    <ModuleWrap
        title="规格参数"
        bg-col="#fff"
        v-if="selectHardware.specificationData"
    >
      <div class="hardware-box">
        <table border="0" cellspacing="0" class="specification-wrap" style="width:100%">
                <tr>
                  <td colspan="2" style="font-weight:600" v-show="selectHardware.specificationData[0].type">型号：{{selectHardware.specificationData[0].xh}}</td>
                  <td colspan="2" style="font-weight:600" v-show="!selectHardware.specificationData[0].type">{{selectHardware.specificationData[0].xh}}</td>
                </tr>
                <tr v-for="(item,ind) in selectHardware.specificationData" :key="ind">
                  <td :style="`${selectHardware.specificationData[0].xh == '多通道振弦采集仪' ? 'width:38%':''}`">{{ item.key }}</td>
                  <td>{{ item.value }}</td>
                </tr>
        </table>
      </div>
    </ModuleWrap>
        <!--  规格参数   -->
    <ModuleWrap
        title="规格参数"
        bg-col="#fff"
        v-if="selectHardware.specificationDataZdj"
    >
      <div class="hardware-box">
        <table border="0" cellspacing="0" class="specification-wrap" style="width:100%">
                <tr>
                  <td style="font-weight:600">型号：{{selectHardware.specificationDataZdj[0].xh}}</td>
                </tr>
                <tr v-for="(item,ind) in selectHardware.specificationDataZdj" :key="ind">
                  <td>{{ item.value }}</td>
                </tr>
        </table>
      </div>
    </ModuleWrap>
     <!--  规格参数图   -->
     <ModuleWrap
        title="规格参数"
        bg-col="#fff"
        v-if="selectHardware.specificationImg"
    >
      <div class="hardware-box">
        <img style="width:100%" :src="selectHardware.specificationImg" alt="">
      </div>
    </ModuleWrap>

    <Consultation />
  </div>
</template>

<script>
import Consultation from './consultation';
import ModuleWrap from '../components/moduleWrap';
export default {
  name: "hardware",
  components: {
    Consultation,
    ModuleWrap
  },
  data() {
    return {
      selectHardware: null,
      refreshStyle:true,
      hardwareData: {
        eaigo: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box01.png',
              title: '硬件产品',
              subTitle: 'EAI智算精灵',
              btn: 'contact',
              name: 'EAI智算精灵',
              width:'3.125rem',
              height:'2.125rem',
              right:'0.65rem'
            },
          ],
          // 描述信息
          info: {
            title: '产品概述',
            desc: [
              'EAI智算精灵，是针对生态环保、水利水务等行业的边缘侧智能视频分析盒，产品可以支持水环境漂浮物识别、船只识别、特定区域人员检测、违章建筑、固废物品、料口识别、开关门检测、特种车辆识别、烟火检测、生物多样性识别等30多种高精度深度学习算法。',
              'EAI智能精灵，可接入1~8路视频监控摄像机，支持实时视频分析、轮巡视频分析多种模式，可以实现监测对象的实时分析，检测的事件图片和视频片段上传到万福EAIGO AI能力平台，进而将检测的事件信息推送到生态环保、水利水务等业务管理系统进行研判。',
              'EAI智能精灵,通过万福EAIGO AI能力平台，实现检测任务、算法模型和设备运维管理，每个设备可以配置特定的视频通道数量、通道检测类型和分析模式，支持算法模型在线自动更新，通过业务场景数据的累积，不断提升算法模型的精度，适配新的监测对象和事件。',
              '利用EAI智算精灵，将监管部门关注的重点事件信息上传视频管理平台并长期保存，节约视频传输带宽和后台存储成本，同时结合业务系统对高价值视频事件信息进行二次分析研判，发现异常进行预警，提升非现场监管能力，实现事前布防、 预判，事中现场可视、集中指挥调度，事后可追溯、取证等业务优势。'
            ]
          },
          //产品特点
          characteristic: [
            {
              icon: '/img/hardware/icon01@2x.png',
              name: '实时检测“快”',
              desc: '图片检测速度25fps，支持1~8路实时视频分析。',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              name: '检测种类“多”',
              desc: '支持漂浮物识别、船只识别、特定区域人员检测、违章建筑等30多种高精度深度学习算法。',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              name: '算法调优“准”',
              desc: '对于新的识别对象和场景，支持快速调优，调优时间不超过3小时，算法识别精确度不低于90%。',
            },
            {
              icon: '/img/hardware/icon04@2x.png',
              name: '模型管理“活”',
              desc: '支持模型在线管理，算法调优的模型可以快速更新。',
            },
            {
              icon: '/img/hardware/icon05@2x.png',
              name: '管理效率“高”',
              desc: '边缘侧视频预分析，结合云端的智能视频分析服务，精准定位可疑场景、事件，无需人工查询。\n',
            },
            {
              icon: '/img/hardware/icon06@2x.png',
              name: '建设成本“低”',
              desc: '已建摄像机纳入监管，通过视频AI智能算法，及时发现异常进行预警，提升原视频监控系统智能分析能力。',
            },
            {
              icon: '/img/hardware/icon01@2x.png',
              name: '配置管理“灵”',
              desc: '根据不同的应用需求，接入视频通道、分析模式、检测时间、检测对象和事件等均可以灵活配置。',
            },
          ],
          specificationData: [
            { 
              type:false,
              xh:'EAI智算精灵',
              key:"视频解码",
              value: '8路1080p@25fps',
            },
            {
              key:"视频压缩标准",
              value: 'H.264,H.265硬解码',
            },
            {
              key:"算法模型",
              value: '支持人工水尺读数、危险区域人员检测、钓鱼和游泳行为、水面漂浮物识别、船只识别',
            },
            {
              key:"报警方式",
              value: '事件图片（全景图）、事件录像、事件报警信息；支持预置点预警',
            },
            {
              key:"模型调优",
              value: '支持算法模型在线调优部署',
            },
            {
              key:"模型更新",
              value: '支持算法模型自动版本匹配，在线更新算法模型',
            },
            {
              key:"处理器",
              value: '四核64位Cortex-A57处理器，主频2.0GHz',
            },
            {
              key:"内存",
              value: '4GB 64位LPDDR4@1600MHz',
            },
            {
              key:"网络接口",
              value: '1个RJ-45，10/100/1000Mbps自适应以太网口(千兆电口)',
            },
            {
              key:"供电方式",
              value: 'DC5V4A',
            },
            {
              key:"功耗",
              value: '≤10W',
            },
            {
              key:"产品尺寸",
              value: '107mm*86mm*39mm',
            }
          ],
        },
        slygt: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box02.png',
              title: '硬件产品',
              subTitle: '水利一杆通WF-JC100',
              btn: 'contact',
              width:'2.9rem',
              height:'3.4rem',
              right:'0.55rem'
            },
          ],
          // 描述信息
          info: {
            title: '产品概述',
            desc: '万福水利一杆通解决方案，高度集成传感监测、视频监控、公共服务、一体化设备箱等水利要素。采集、网络传输和电源系统，匹配水文、视频站点全场景需求，广泛应用于水库、河道、流域等多场景监测站点部署。方案采用模块化集成设计、支持多种传输模式、锂电新储能和统一智能网管系统，使水利监测站点部署更简单、性能更可靠、管理更智能。'
          },
          //产品特点
          characteristic: [
            {
              icon: '/img/hardware/icon01@2x.png',
              name: '化繁为简',
              desc: '网络易覆盖，设备低功耗，一体化交付',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              name: '无人值守',
              desc: '远程维护省人力，网络永不掉线，开箱报警防盗窃',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              name: '边云协同',
              desc: '边缘智能，智能识别水情。视频巡查，无漏洞',
            },
            {
              icon: '/img/hardware/icon04@2x.png',
              name: '智能联动',
              desc: '公众互动水情实时信息，灾害预警，广播联动',
            }
          ],
          specificationImg: '/img/hardware/specification01.png',
        },
        csyt: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box03.png',
              title: '硬件产品',
              subTitle: '存算一体VGIS-N7100',
              btn: 'contact'
            },
          ],
          info: {
            title: '产品概述',
            desc: '存算一体VGIS-N7100，基于国产化算力平台实现计算、存储、网络以及统一管理的优化整合，形成软硬一体化产品。 产品在提供高性能计算的同时，还具备超大容量存储和物联扩展能力，为用户提供集成度高、性能优、易维护的计算平台，一站式解决客户数据采集、存储、处理、应用等全生命周期应用需求。'
          },
          //产品特点
          characteristic: [
            {
              icon: '/img/hardware/icon01@2x.png',
              name: '模块化设计理念',
              desc: '支持多种AI芯片的主控AI板，以及通用的后插IO卡',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              name: '标准化设计理念',
              desc: '主控AI板和后插IO卡尺寸标准化设计，灵活互换互插',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              name: '丰富的外设接口',
              desc: '通过新增I0卡快速满足客户定制化的场景接口种类和数量需求',
            },
            {
              icon: '/img/hardware/icon04@2x.png',
              name: '专业工业级设计',
              desc: '芯片工业级选型，满足高低温等多样化的工作场景要求',
            },
            {
              icon: '/img/hardware/icon05@2x.png',
              name: '带外网管的设计',
              desc: '支持独立的BMC远程IO卡，满足极致的远程管理需求',
            },
            {
              icon: '/img/hardware/icon06@2x.png',
              name: '软硬件整体方案',
              desc: '内置软件管理平台并支持隐溪私有AloT云，阿里云、腾讯云等公有云平台接入',
            }
          ],
          specificationData: [
            { 
              type:false,
              xh:'存算一体VGIS-N7100',
              key:"视频解码",
              value: '8路1080p@25fps',
            },
            {
              key:"视频压缩标准",
              value: 'H.264,H.265硬解码',
            },
            {
              key:"算法模型",
              value: '支持人工水尺读数、危险区域人员检测、钓鱼和游泳行为、水面漂浮物识别、船只识别',
            },
            {
              key:"报警方式",
              value: '事件图片（全景图）、事件录像、事件报警信息；支持预置点预警',
            },
            {
              key:"模型调优",
              value: '支持算法模型在线调优部署',
            },
            {
              key:"模型更新",
              value: '支持算法模型自动版本匹配，在线更新算法模型',
            },
            {
              key:"处理器",
              value: '八核64位Cortex-A53处理器，主频2.3GHz',
            },
            {
              key:"内存",
              value: '8GB LPDDR4@1600MHz',
            },
            {
              key:"网络接口",
              value: '2个RJ-45，10/100/1000Mbps自适应以太网口（千兆电口）',
            },
            {
              key:"供电方式",
              value: 'DC24V(2pin 凤凰端子座)',
            }
          ]
        },
        //遥测终端机
        rtu: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box04.png',
              title: '硬件产品',
              subTitle: '遥测终端机',
              btn: 'contact',
              width:'4.44rem',
              height:'1.8rem',
               right:'0.65rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '遥测终端机是一种用于远程测量、监控和数据传输的设备，广泛应用于工业、能源、环境监测、水利、气象和其他领域，以实现对远程站点的实时监控和数据采集。万福遥测终端机是集水文水资源、视频数据采集和逻辑控制功能于一体的工业级网关型遥测终端机，采用大容量存储和高级路由等新型设计方式，通信网络可采用5G/4G/GPRS等多种无线网络或本地宽带网络将数据传输至中心平台。广泛应用于水文、水资源、智慧排水、智慧供水、智慧农业等综合测控领域。'
          },
          //产品特点
          characteristic: [
            {
              icon: '/img/hardware/icon01@2x.png',
              name: '多路采集数据存储空间自定义',
              desc: '支持多路采集数据存储空间自定义配置，每个采集数据的存储空间均支持自定义配置',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              name: '传感器定制简单可配可选',
              desc: 'Modbus RTU 传感器不用软件定制可以兼容',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              name: '轻松实现远程管理，节省人力物力',
              desc: '支持设备故障告警、远程参数配置、定时采集与上报中心平台、远程实时数据、历史数据查询',
            }
          ],
          specificationDataZdj: [
            {
              xh:'WF-R100',
              value: '支持SL 651-2014《水文监测数据通信规约》（ASCII 和HEX 全项）,通过水利部水文仪器及岩土工程仪器质量监督检验测试中心规约符合性测试',
            },
            {
              value: '支持有线宽带和无线网络互为备份，降低通信故障概率',
            },
            {
              value: '支持远程运维和远程调试功能，降低运维成本',
            },
            {
              value: '多中心设计，监控数据可同时上报省、市、县级水文管理平台',
            },
            {
              value: '支持GPRS/4G 无线蜂窝网络、RS232/RS48',
            },
            {
              value: '硬件配置：工业级32 位通信处理器；配备丰富的行业应用接口，支持3个RS485/RS232接口、4 路开关量输入接口、8路模拟量输入接口、2路继电器输出、4路电源输出等，可兼容采集多种工业传感器采集需求',
            },
            {
              value: '工作温度：-35℃～75℃',
            },
          ]
        },
        dtdzxcjy: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box05.png',
              title: '硬件产品',
              subTitle: '多通道振弦采集仪',
              btn: 'contact',
              width:'4rem',
              height:'3rem',
              right:'0.55rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '万福多通道振弦采集仪，专为振弦型传感器的数据采集设计，精确采集传感器的频率和温度数据。可方便的应用于各种安全监测项目中。例如：水库大坝安全、滑坡防护等。系列产品有单通道WF-ZX01、四通道WF-ZX04、十通道WF-ZX10多个型号，适用于不同不同类型的项目。为了适应现场复杂的安装环境，产品外形结构轻便小巧，满足IP65防护等级要求。'
          },
          //产品特点
          characteristic: [
            {
              icon: '/img/hardware/icon01@2x.png',
              desc: '兼容国内外多种类型振弦传感器',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              desc: '采集仪采用标准MODBUS RTU协议',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              desc: '测量精度高，分辨率到0.1Hz',
            },
            {
              icon: '/img/hardware/icon04@2x.png',
              desc: '智能激励算法，读取速度最快到2Hz',
            },
            {
              icon: '/img/hardware/icon05@2x.png',
              desc: '支持温度采集通道，分辨率精度可达0.1℃',
            },
            {
              icon: '/img/hardware/icon06@2x.png',
              desc: '低功耗模式，可采用电池供电系统',
            },
            {
              icon: '/img/hardware/icon01@2x.png',
              desc: '内置高精度时钟，定时上传数据',
            },
            {
              icon: '/img/hardware/icon02@2x.png',
              desc: '宽工作电压范围，电源反接保护',
            },
            {
              icon: '/img/hardware/icon03@2x.png',
              desc: '本地液晶显示，实时显示各通道读值(部分产品)',
            },
          ],
          specificationData: [
            {
              type:false,
              xh:'多通道振弦采集仪',
              key: '量程（可配置）',
              value: '400 – 6000Hz',
            },
            {
              key: '分辨率',
              value: '0.1Hz'
            },
            {
              key: '全频扫描时间',
              value: '＜5s(1 个通道)',
            },
            {
              key: '连续测量时间(捕获)',
              value: '＜2s(1 个通道)',
            },
            {
              key: '传感器激励',
              value: '12V（正弦波)',
            },
            {
              key: '通道数量',
              value: '4路/8路',
            },
            {
              key: '温度测量范围',
              value: '-40℃ - 125℃',
            },
            {
              key: '温度传感器类型',
              value: 'NTC(默认 3KΩ)',
            },
            {
              key: '工作模式',
              value: '被动轮询模式',
            },
            {
              key: '供电电源范围',
              value: '15 – 30VDC',
            },
            {
              key: '工作时电源功耗',
              value: '≤1W',
            }
          ],
        },
        //雨量计
        hyetometer:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box06.png',
              title: '前端传感器',
              subTitle: '雨量计',
              btn: 'contact',
              width:'1.49rem',
              height:'2.97rem',
              right:'1.5rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: ' 全不锈钢雨量计是一种用于测量降水量的设备，用于监测雨水的强度和时长。为降水量测量一次仪表，其性能符合国家标准GB/T21978.2-2014《降水量观测要求》要求。翻斗采用三维流线型设计，全不锈钢材质制作，翻水性能更加流畅，且具有自涤灰尘、容易清洗的功能。'
          },
          specificationData: [
            {
              xh:'WF-YL-PL-05',
              type:true,
              key:"雨量计筒直径",
              value: '200mm',
            },
            {
              key:"分辨率",
              value: '0.5mm',
            },
            {
              key:"刃口锐角",
              value: '40°-45°',
            },
            {
              key:"工作温度",
              value: '0℃~50℃',
            },
            {
              key:"工作湿度",
              value: '<95%(40°℃)',
            },
            {
              key:"信号输出",
              value: '脉冲信号输出',
            },
            {
              key:"测量精度",
              value: '≤土3%',
            },
            {
              key:"雨强范围",
              value: '0mm~4mm/min，允许通过最大雨强8mm/min',
            },
           
          ],
        },
        //气泡式水位计
        qpsswj:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box07.png',
              title: '前端传感器',
              subTitle: '气泡式水位计',
              btn: 'contact',
              width:'2.375rem',  //以36px为基准
              height:'1.889rem',
              right:'1.3rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '气泡式水位计是新一代高精度智能型气泡式水位计，产品执行GB/T11828标准，具有安装方便、高精度、高可靠性及非接触式测量、兼容性高、多种通讯接口等显著优点。本产品通常用于需要简单、经济且可靠的水位测量场合，特别适合中小河流水位监测、大坝上下游水位的测量；海洋、地下水水位的测量;化工、煤矿、污水处理、自来水厂等处的水位及液位的监测;适用于不便建测井或建测井费用昂贵的地区。'
          },
          specificationData: [
            {
              xh:'WF-Q30',
              type:true,
              key:"产品量程",
              value: '0~30m（其他量程可定制）',
            },
            {
              key:"分辨率",
              value: '1mm',
            },
            {
              key:"准确定",
              value: '≤±0.03%FS',
            },
            {
              key:"稳定性",
              value: '<0.1%FS/年',
            },
            {
              key:"测量介质",
              value: '水',
            },
            {
              key:"采样间隔",
              value: '1分钟~24小时(可更改)',
            },
            {
              key:"通讯方式",
              value: 'RS232/RS485，波特兰默认9600bps，可自行设定',
            },
            {
              key:"防护等级",
              value: 'IP66',
            },
            {
              key:"工作环境",
              value: '-20℃~+55℃（95%RH@40℃）',
            }
          ]
        },
        //雷达水位计
        ldswj:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box08.png',
              title: '前端传感器',
              subTitle: '雷达水位计',
              btn: 'contact',
              width:'3.05rem',  //以36px为基准
              height:'2.17rem',
              right:'1.3rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '雷达水位计是一种利用雷达技术测量水体水位的设备，通过发射雷达波并测量其反射时间来确定水面的距离，从而实现对水位的准确测量，不受温度、湿度、水中污染物、漂浮物等影响。测量精度高、功耗极低、体积小、安装便捷、免维护，广泛应用于水文测量、水库管理、河流水位监测、洪水预警系统、海洋测量以及工业和环境监测等领域。其非接触性和远距离测量的能力使其适用于各种水体环境。'
          },
          specificationData: [
            {
              xh:'WF-LD50',
              type:true,
              key:"量程",
              value: '0-30米(其他量程可定制)',
            },
            {
              key:"测量角度",
              value: '6-8°',
            },
            {
              key:"输出",
              value: '4~20mA/RS485，支持modbus协议',
            },
            {
              key:"分辨率",
              value: '1mm',
            },
            {
              key:"稳定性",
              value: '不受噪声、灰尘、色彩、直射与散射光线等自然干扰源影响',
            },
            {
              key:"工作温度",
              value: '0℃~+60℃（95%RH@40℃）',
            }
          ]
        },
        //振弦式渗压计
        zxssyj:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box09.png',
              title: '前端传感器',
              subTitle: '振弦式渗压计',
              btn: 'contact',
              width:'3.343rem',  //以36px为基准
              height:'2.02rem',
              right:'1rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '振弦式渗压计是一种测量软土地基深层孔隙水压力的传感器，具有较高的灵敏度和快速的响应时间。广泛应用于:大坝、尾矿库、隧道、路基、边坡等工程中地基深层水压力的测量,从预钻孔埋入至设计待测点。'
          },
           specificationData: [
            {
              xh:'WF-SY-02',
              type:true,
              key:"测量范围",
              value: '0~0.2MPa/0.4MPa/0.6MPa/0.8MPa/1MPa/2.5MPa',
            },
            {
              key:"温度测量范围",
              value: '-25℃～+60℃',
            },
            {
              key:"不重复度",
              value: '≤0.5%FS',
            },
            {
              key:"迟滞",
              value: '≤1.0%FS',
            },
            {
              key:"非线性度",
              value: '≤2.0%FS',
            },
            {
              key:"综合误差",
              value: '≤2.5%FS',
            },
            {
              key:"耐水压(MPa)",
              value: '满量程的1.2倍',
            },
            {
              key:"可靠性(MTTF)",
              value: '≥40000h',
            }
          ]
        },
        //磁致式量水堰计
        czslsyj:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box10.png',
              title: '前端传感器',
              subTitle: '磁致式量水堰计',
              btn: 'contact',
              width:'2.8rem',  //以36px为基准
              height:'2.9rem',
              right:'1rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '磁致式量水堰计是一种利用磁致伸缩效应来测量水位或水位变化的设备，具有测量精度高、抗干扰能力强、使用寿命长等优势。由于没有直接与水接触的机械部件，因此具有较好的耐腐蚀性能，适用于恶劣的环境条件。可以广泛应用于水文测量、河流水位监测、水库水位管理、洪水预警系统、海洋测量等领域。其稳定性和精确性使其成为水文学和水资源管理中重要的测量工具。'
          },
          // 规格参数
          specificationData: [
            {
              xh:'WF-CZ-100',
              type:true,
              key:"测量范围",
              value: '0~600mm(量程自选)',
            },
            {
              key:"灵敏度",
              value: '≤0.01mm',
            },
            {
              key:"测量精度",
              value: '≤0.1%FS',
            },
            {
              key:"输出信号",
              value: 'RS485',
            },
            {
              key:"上报方式",
              value: '自报/召测',
            },
            {
              key:"存储温度",
              value: '-30℃~+70℃',
            }
          ],
        },
        //流量计
        flowmeter:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box13.png',
              title: '前端传感器',
              subTitle: '流量计',
              btn: 'contact',
              width:'2.66rem',
              height:'1.69rem',
              right:'1.5rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '多普勒雷达流量计是一种非接触的测试设备，集成了微带天线、射频电路、及信号处理模块，可直接获取流体表面流速信息。流速雷达通过水体表面波移动形成的多普勒效应来进行测量。建议流速传感器模块安装面朝向上游，水流速度靠近流速传感器模块，速度显示正速度。雷达发射方向如果有水、金属等反射较强的遮挡物，会严重影响雷达测试效果。水位雷达则可以获取水位的数据，结合基底数据就可以推算出过水面积，与流速数据相乘就可以获得流量数据。产品可以广泛应用于水文、水利、环保、水务等部门在江、河、渠道等流速与流量测量。'
          },
          specificationData: [
            {
              xh:'WF-LL-100',
              type:true,
              key:"流速量程",
              value: '0.100~20.000m/s',
            },
            {
              key:"流速分辨率",
              value: '0.001m/s',
            },
            {
              key:"流速精度",
              value: '3%±0.01m/s',
            },
            {
              key:"死区流速",
              value: '0~0.01m/s',
            },
            {
              key:"流速雷达",
              value: '24G(工作频率),CW,天线角度：24°×12°,辐射功率EIRP:20dBm,天线增益：18.5dB',
            },
            {
              key:"水位雷达",
              value: '80G量程：0.15-40米(受RCS影响),FMCW,天线角度：14°×10°(建议安装高度2~30m)',
            },
            {
              key:"水位分辨率",
              value: '0.001m',
            },
            {
              key:"水位精度",
              value: '0.2%±0.002m',
            },
            {
              key:"流速方向识别",
              value: '双向自动识别',
            },
            {
              key:"主机壳体",
              value: '铝壳',
            },
            {
              key:"工作温度",
              value: '-10~70℃',
            },
            {
              key:"功耗",
              value: '12VDC/260mA',
            },
            {
              key:"响应时间",
              value: '10秒',
            }
           
          ],
        },
        //表面变形监测
        bmbxjc:{
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/hardware/img_box14.png',
              title: '前端传感器',
              subTitle: '表面变形监测',
              btn: 'contact',
              width:'2.78rem',
              height:'2.56rem',
              right:'1.2rem'
            },
          ],
          info: {
            title: '产品概述',
            desc: '一款专业适用于高精度地表位移及沉降监测的普适型GNSS监测接收机,其采用最新高精度芯片,内置MEMS传感器,支持 MEMS联动及自动触发加密观测,结合先进前端算法,具有精度高、集成度高,防护等级高,性价比高,以及功耗低、安装难度低、WebUI 智能操作以及处理性能强大等特点,其支持前端边缘计算,基于Linux智能系统,支持智能化远程运维,运维成本低,智能化程度高,可完全满足各类型监测工程(地灾矿山、水利、大坝、边坡等),以及便捷型监测基站建设等需求,适应于野外环境下的长时间监测。'
          },
          specificationData: [
            {
              xh:'WF-GN-100',
              type:true,
              key:"跟踪特性",
              value: 'BDS B11/ B21/ B31/B1C/B2a:GPS L1C/A/L2P/L5:GLONASS ',
            },
            {
              key:"L1/L2",
              value: 'GLONASS L1/L2Galileo E1/E5b/E5a;QZSS L1/L2/L5',
            },
            {
              key:"监测精度",
              value: '平面: 土(25mm+0.5*10D) RMS，高程: 土(5mm+0.5*10-D) RMS',
            },
            {
              key:"RTK定位精度",
              value: '平面: 士8mm+lppm(RMS)，高程: 土15mm+1ppm(RMS)',
            },
            {
              key:"内置存储",
              value: '32GB，可拓展512G存储',
            },
            {
              key:"输出参数",
              value: '位移、倾角、加速度、原始数据和状态值等参数上传',
            },
            {
              key:"无线通信",
              value: '支持4G、蓝牙、内置LoRa',
            },
            {
              key:"远程控制",
              value: '支持远程监控、远程升级、远程配置 、定位跟踪、智能远程运维',
            },
            {
              key:"电压输入",
              value: '外接电源，9-36V宽压输入，通电自启动',
            },
            {
              key:"功耗",
              value: '1.5W',
            },
            {
              key:"防水防尘",
              value: 'IP68',
            }
           
          ],
        },
      }
    }
  },
  watch: {
    "$route.params.id": {
      handler(val, old){
        if(val!==old){
          let hardwareId = val
          this.selectHardware = this.hardwareData[hardwareId]
          this.refreshStyle = false
          this.$nextTick(()=>{
            this.refreshStyle = true
          })
        }
      },
      deep: true
    }
  },
  created() {
    let hardwareId = this.$route.params.id
    this.selectHardware = this.hardwareData[hardwareId]
  },
  mounted() {
    let hardwareId = this.$route.params.id
    this.selectHardware = this.hardwareData[hardwareId]
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
.hardware-banner {
  width: 100%;
  height: 160px;
  background: url("/img/hardware/img_top01.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .hardware-banner-title {
    position: absolute;
    left: 32px;
    top: 52px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #929CAA;
    line-height: 20px;
  }
  .hardware-banner-sub-title {
    position: absolute;
    left: 32px;
    top: 80px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #fff;
    line-height: 28px;
  }
  .hardware-banner-sub-img {
    position: absolute;
    right: 0;
    top: 0;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
    }
  }
}
.content-card {
  margin-top: 16px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.03);
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: $primary-text;
  line-height: 24px;
  text-indent: 2em;
  word-break: break-all;
  text-align: justify;
}
.hardware-box {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  justify-content: flex-start;
  margin: 16px -6px -6px -6px;
  .hardware-item {
    //padding: 16px 12px 24px 12px;
    box-sizing: border-box;
    margin: 0 20px;
    margin-top: 32px;
    width: calc(50% - 40px);
    //background-image: linear-gradient(0deg, #ffffff, #f3f5f8);
    //border: 2px solid #fff;
    //background: url("/img/solution/card.png") no-repeat;
    //background-size: 100% 100%;
    //height: 170px;
    //border-radius: 4px;
    //box-shadow: 0px 4px 25px 0px rgba(216,222,228,0.50);
    .hardware-item-img {
      width: 32px;
      height: 32px;
      margin-left: calc(50% - 16px);
    }
    .hardware-item-name {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      color: $primary-text;
      line-height: 22px;
      letter-spacing: 0.02px;
    }
    .hardware-item-desc {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      //letter-spacing: 0.02px;
      word-break: break-all;
      text-align: justify
      ;
    }
  }
}
  .specification-wrap{
    background: #fff;
    font-size: 14px;
    tr{
      td{
       /*  height: 40px; */
        padding: 10px 16px;
        border-right: 1px solid #d2d8dc;
        border-bottom: 1px solid #d2d8dc;
        word-break: break-all;
        &:nth-child(1){
          width: 35.8%;
        }
        &:nth-last-child(1){
          text-align: justify;
        }
        &:last-child{
          border-right: 0;
        }
        &.span-cell{
          background: #fff;
        }
        &.no-border{
          border-bottom: 0;
        }
      }
      &:nth-child(odd){
        background: #eceef1;
      }
      /* &:last-child{
        td{
          border-bottom: 0;
        }
      } */
    } 
    tr:first-child{
      font-size: 16px;
      color: #ffffff;
      background: #8598aa;
      td{
        width:100%;
      }
    }
    
  }
  .table1 {
    tr{
      td{
       /*  padding:10px 80px; */
        border: none;
      }
    }
  }
  .table2 {
    tr{
      td{
       /*  padding:10px 80px; */
      }
    }
  }
</style>

<template>
  <div>
    <div class="header-box" :style="{height: headerHeight + 'PX'}">
      <div>
        <!-- 万福信息 -->
        <img v-if="system === 'wanfu'" class="header-box-logo" src="/img/layout/header/img_logo.png" alt="" @click="goToHome">
        <!-- 南威 -->
        <img v-if="system === 'nanwei'" class="header-box-logo" src="/img/layout/header/img_logo_nanwei.png" alt="" @click="goToHome">
      </div>
      <div style="width: 48px;height: 48px;display: flex;align-items: center;justify-content: flex-end" @click="showHideMoreFun">
        <img
          class="header-box-more"
          :src="showMore ? '/img/layout/header/icon_close.png' : '/img/layout/header/icon_more.png'"
          alt=""
        >
      </div>
    </div>
    <div
      v-show="showMore"
      class="menu-box"
      :style="{height: `calc(100% - ${headerHeight}PX)`}"
    >
      <div class="menu-box-left">
        <div
            v-for="(item,index) in menuList"
            :key="item.path || index"
            :class="menuStyle(item)"
            @click="onClickFirstLevelMenu(item)"
        >{{item.name}}</div>
      </div>
      <div class="menu-box-right" v-if="menuSecondLevelList">
        <!-- 有标题-->
        <div v-if="menuSecondLevelList.isTitle">
          <div v-for="(item,index) in menuSecondLevelList.children" :key="index">
            <div class="menu-box-right-item-title">{{item.name}}</div>
            <div
                class="menu-box-right-item"
                v-for="items in item.children"
                :key="items.name"
                @click="onClickSecondLevelMenu(items)"
            >{{items.name}}</div>
          </div>
        </div>
        <!-- 无标题-->
        <div v-else style="margin-top: 20px">
          <div
            v-for="(item,index) in menuSecondLevelList.children"
            :key="index"
            class="menu-box-right-item"
            @click="onClickSecondLevelMenu(item)"
          >
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {system} from '@/config/system_type';
import { headerHeight } from '@/config/layout_config';
import {menuList} from "@/mock_data/menu_data";
export default {
  name: "layout-header",
  data() {
    return {
      system,
      headerHeight,
      showMore: false,
      nowFirstRouter: null,
      menuList,
      menuSecondLevelList: null,
    }
  },
  created() {
    this.nowFirstRouter = '/solutions'
    // this.nowFirstRouter = '/' + this.$router.history.current.path.split('/')[1]
    if (this.menuList.filter((item)=>(item.path === this.nowFirstRouter)).length > 0) {
      this.menuSecondLevelList = this.menuList.filter((item)=>(item.path === this.nowFirstRouter))[0]
    }
  },
  mounted() {
  },
  methods: {
    /** 跳转首页 **/
    goToHome() {
      if ('/home' !== this.$router.history.current.path) {
        this.$router.push('/home')
      }
      this.showMore = false
    },
    /** 显示菜单 **/
    showHideMoreFun() {
      this.showMore = !this.showMore
      this.nowFirstRouter = '/solutions'
      if (this.menuList.filter((item)=>(item.path === this.nowFirstRouter)).length > 0) {
        this.menuSecondLevelList = this.menuList.filter((item)=>(item.path === this.nowFirstRouter))[0]
      }
    },
    /** 菜单选中样式 **/
    menuStyle(item) {
      let className = 'menu-box-left-item'
      if (item.path === this.nowFirstRouter) {
        className += ' menu-box-left-item-active'
      }
      return className
    },
    /** 点击一级菜单 **/
    onClickFirstLevelMenu(item) {
      this.menuSecondLevelList = item
      this.nowFirstRouter = item.path
      if (!item.children) {
        if (item.path !== this.$router.history.current.path) {
          this.$router.push(item.path);
        }
        this.showHideMoreFun();
      }
    },
    /** 点击二级菜单 **/
    onClickSecondLevelMenu(item) {
      this.showHideMoreFun();
      if (item.path !== this.$router.history.current.path) {
        this.$router.push(item.path);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/util";
@import "src/assets/css/style";
.header-box {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(223,223,223,0.50);
  z-index: 200;
  overflow: hidden;
  .header-box-logo {
    //width: 80px;
    margin-top: 4px;
    height: 32px;
  }
  .header-box-more {
    width: 16px;
  }
}
.menu-box {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 50;
  display: flex;
  .menu-box-left {
    width: 94px;
    height: 100%;
    border-right: 1px solid #eeeeee;
    .menu-box-left-item {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-size: 14px;
      color: $primary-text;
      line-height: 20px;
      position: relative;
    }
    .menu-box-left-item-active {
      color: $primary-color;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
        width: 4px;
        height: 16px;
        background: $primary-color;
        @include text-ellipsis-line(1)
      }
    }
  }
  .menu-box-right {
    width: calc(100% - 94px);
    height: 100%;
    overflow: auto;
    .menu-box-right-item-title {
      position: relative;
      width: 100%;
      height: 60px;
      background: #ffffff;
      font-size: 14px;
      font-weight: 700;
      color: $primary-text;
      padding: 0 0 0 20px;
      box-sizing: border-box;
      line-height: 60px;
      margin-bottom: 8px;
      &:before {
        content: '';
        position: absolute;
        left: 20px;
        bottom: 0;
        width: calc(100% - 40px);
        height: 1px;
        background: #eeeeee;
      }
    }
    .menu-box-right-item {
      position: relative;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 38px;
      box-sizing: border-box;
      font-size: 14px;
      color: $regular-text;
      letter-spacing: 0.2px;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        left: 20px;
        width: 6px;
        height: 6px;
        background: $primary-color;
        border-radius: 50%;
      }
    }
  }
}
</style>

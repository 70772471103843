<template>
  <div class="wanfuH5Tabs-box" >
    <div
      v-for="item in caseData"
      :key="item.label"
      class="wanfuH5Tabs-box-item"
      :class="item.value === selectTabs.value && 'wanfuH5Tabs-box-item-active'"
      @click="onClickTabs(item)"
    >
      {{item.label}}
      <div class="wanfuH5Tabs-box-item-active-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wanfuH5Tabs",
  props: {
    data: {
      default: [],
    },
  },
  data() {
    return {
      selectTabs: {value: '1',label: '水环境'},
      caseData: []
    }
  },
  mounted() {
    this.caseData = this.data
  },
  methods: {
    onClickTabs(item) {
      this.selectTabs = item;
      this.$emit('onClick',item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
.wanfuH5Tabs-box {
  width: 100%;
  height: 48px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  .wanfuH5Tabs-box-item {
    width: 100%;
    display:inline;
    white-space: nowrap;
    float: left;
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #333333;
  }
  .wanfuH5Tabs-box-item-active {
    color: $primary-color;
    font-size: 700;
    position: relative;
    .wanfuH5Tabs-box-item-active-line {
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 2px;
      background: $primary-color;
    }
  }
}
</style>

<template>
  <div class="h5-box">
    <div class="case-detail-box">
      <div class="case-detail-title">{{caseIntro.name}}</div>
      <div class="case-detail-desc">{{caseIntro.desc}}</div>
      <div class="aboutwf-video" @click="play" v-show="caseIntro.videoUrl">
        <div class="play-icon" v-show="!playing" @click.stop="play"></div>
        <video
            width="100%"
            :controls="playing"
            height="100%"
            ref="video"
            poster="/img/home/videoImg.png"
            webkit-playsinline='true'
            playsinline='true'
        >
          <source :src="caseIntro.videoUrl" type="video/mp4" />您的浏览器不支持Video标签。
        </video>
      </div>
      <img class="case-detail-img" v-for="(item,index) in caseIntro.imgUrl.split(',')" :key="index" :src="item" alt="">
    </div>
    <ModuleWrap
        style="padding-bottom: 32px"
        class="more-case-box"
        bg-col="#f3f5f7"
    >
      <div class="more-case-title">更多案例</div>
      <div
          class="more-case-card-item"
          v-for="item in moreCase"
          :key="item.name"
          @click="goToCaseDetail(item)"
      >
        <img class="more-case-card-item-img" :src="item.icon" alt="">
        <div class="more-case-card-item-box">
          <div class="more-case-card-item-title">{{item.name}}</div>
          <div class="more-case-card-item-desc">{{item.desc}}</div>
        </div>
      </div>
    </ModuleWrap>
<!--    产品咨询-->
    <Consultation />
  </div>
</template>

<script>
import ModuleWrap from '../components/moduleWrap'
import Consultation from "./consultation";
export default {
  name: "caseDetail",
  components: {
    Consultation,
    ModuleWrap
  },
  data() {
    return {
      playing: false,
      caseIntro: {},
      caseDetail: {
        //德化县小型水库雨水情测报和大坝安全监测项目
        dhsk: {
          title: '水工程',
          name: '德化县小型水库雨水情测报和大坝安全监测项目',
          desc: '本项目通过对德化红洋水库、隆泰水库、红星水库、东风水库的水位、雨量、大坝安全、视频等前端感知设备的升级和补充，实现将水库物联感知全覆盖，感知能力得到显着提升；同时打造 AI 智能识别等重点领域智能化应用示范，提高水库的管理效率和业务能力。',
          imgUrl: '/img/case/case1.png',
          moreCase: [
            {
              icon: '/img/case/case14.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/case/case12.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //漳浦县水库雨水情和安全设施监测系统
        zpxsk: {
          title: '水工程',
          name: '漳浦县水库雨水情和安全设施监测系统',
          desc: '本项目建设以“统一规划、上下连接，讲求效益、注重应用”为原则，以水库雨水情和大坝安全监测物联网+视频AI算法智能分析管控为目标，基于现有设施资源的基础上，按照水利设施感知信息完善化、精细化、智能化的管理思路，让监管人员对水库的雨水情和安全监测监管，实现远程化监管应用，可视化决策分析。',
          imgUrl: '/img/case/case15.png,/img/case/case16.png',
          videoUrl: '/img/case/video.mp4',
          moreCase: [
            {
              icon: '/img/case/case14.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/case/case12.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //罗源南门溪、起步溪水环境综合整治项目
        lynmx: {
          title: '水环境',
          name: '罗源南门溪、起步溪水环境综合整治项目',
          desc: '罗源南溪、起步溪水环境综合整治项目以改善罗源县水环境质量为核心，按照“治理整体化、截污最大化、两岸景观化、沿岸生态化、管理制度化”的建设精神，确保本区域资源开发与防洪、水利、环境功能综合协调。开展流域内主要断面水质监测分析和预警工作，对流域沿线工业企业、农业面源、水产养殖、畜禽养殖、牛蛙养殖污染状况、农村生活污水治理情况等各类排口精准摸排，形成问题清单；通过“数字治水”有效监督和解决“乱占”、“乱采”、“乱堆”、“乱建”的“四乱”问题，最终实现各级各部门信息共享，将每个环节的责任落实到具体单位和具体责任人，实现综合执法工作联动。项目用新技术、新机制落实河长制，最大限度地发挥其环境效益、社会效益及经济效益，实现水长清、岸长绿、河长治。',
          imgUrl: '/img/case/case2.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //泉州市市政信息化项目
        qzsszxxh: {
          title: '核心能力',
          name: '泉州市市政信息化项目',
          desc: '泉州市政信息化系统是由四个项目组成的全方位、立体化的监测网以及集网络、数据库、地理信息技术于一体的综合、全面的业务信息平台管理体系，为泉州市市政工程中心正常业务的开展提供了强有力支撑，为防灾减灾提供了强有力保障。项目包括泉州市政监控调度指挥软件平台（含APP）、泉州市政防洪排涝管理系统、视频监控系统、运维管理记录平台以及智控中心。',
          imgUrl: '/img/case/case3.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //泉州市水资源综合调度平台-智慧水利一期
        qzzhsl: {
          title: '水资源',
          name: '泉州市水资源综合调度平台-智慧水利一期',
          desc: '泉州市水资源综合调度平台-智慧水利一期充分运用云计算、物联网、大数据、移动互联网、人工智能等新一代信息技术，建设泉州市智慧水利平台。平台通过强化水资源调度与小流域山洪灾害预报预警模型算法，实现泉州水资源“多源互补、丰枯调剂、环境优美”的科学联合调度目标。泉州市水资源综合调度平台累计汇聚了1500多个水利设施基础信息，包括水库、水闸、提防、泵站、引水工程、水电站等，同时接入了900多个气象、水文、预警、山洪的实时监测站点，累积采集了4000万多的实时监测数据，为泉州市智慧水利的建设奠定坚实的基础，有效助力了泉州“上蓄下引、河库连通”的水网工程体系建设。',
          imgUrl: '/img/case/case17.png,/img/case/case18.png,/img/case/case19.png,/img/case/case20.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //晋江生态环境一张图
        jjstyzt: {
          title: '核心能力',
          name: '晋江生态环境一张图',
          desc: '全省第一个基于生态云总体系统框架，打造的具有晋江特色的业务应用系统，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能，初步搭建了晋江市大气、水、土壤智能管控“一张图”的基本框架，创造生态文明建设的“晋江经验”，厚植全面建成小康社会的绿色底色和质量成色。',
          imgUrl: '/img/case/case4.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //海南省定安县城乡污水治理项目
        hnsdaxwszl: {
          title: '水环境',
          name: '海南省定安县城乡污水治理项目',
          desc: '本项目涉及定安县黄竹、龙湖、龙门、翰林等4个建制镇及其下辖的35个行政村(共计343个自然村)，覆盖面积5260K㎡，建成运行后将服务当地115695人，可削减污染物排放量:污水量1663m3/d，CODcr约260吨/年，氨氨约20吨/年。对实现定安县黄竹、龙湖、龙门、翰林4个地区的乡村振兴有着深远意义。',
          imgUrl: '/img/case/case5.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //仙游县农村生活污水提升治理智慧监管项目
        xyncshws: {
          title: '水环境',
          name: '仙游县农村生活污水提升治理智慧监管项目',
          desc: '仙游县农村生活污水提升治理智慧监管项目根据农村生活污水设施处理规模和所在区域环境敏感程度等因素，采用在线监测和手工监测相结合、水质监测和工况监控相结合、环境监管及属地巡检相结合的方式，充分应用物联网和信息化等非现场监管手段，开展农村生活污水处理设施分类监测监控工作，督促设施稳定运行、尾水达标排放。通过安装县域农村生活污水监管系统，对接省生态云平台绿盈乡村板块，汇聚设施分类监测监控、问题转办督办等涉及农村生活污水提升治理任务的相关数据，统一建档落图，实现非现场巡查、自动化预警、在线调度管理等功能，全面提升仙游县农村生活污水治理智慧监管水平。',
          imgUrl: '/img/case/case6.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //福建省生态环境厅海漂垃圾智能监管项目
        hpljznjg: {
          title: '水环境',
          name: '福建省生态环境厅海漂垃圾智能监管项目',
          desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
          imgUrl: '/img/case/case7.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //厦门湾海漂垃圾漂移轨迹预测系统业务化运行项目
        xmwhplj: {
          title: '水环境',
          name: ' 厦门湾海漂垃圾漂移轨迹预测系统业务化运行项目',
          desc: '（一）实现了厦门全海域海漂垃圾漂移轨迹预测实现日常业务化预报，模拟计算各个重点海域海漂垃圾的可能漂移路径、岸滩垃圾的可能分布情况；（二）在九个重点岸段布设高清摄像头小尺度持续监管，利用人工智能技术，及时发现和掌握海漂垃圾动态，指导科学部署环卫保洁力量，服务精准治理，提升海洋生态环境管理水平，助力美丽海湾建设。',
          imgUrl: '/img/case/case8.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //福州市海漂垃圾智能监管项目
        fzhplj: {
          title: '水环境',
          name: '福州市海漂垃圾智能监管项目',
          desc: '（一）开展海漂垃圾治理的督查考评工作，组织市海漂垃圾整治考评小组成员单位对各沿海县（市）区的海漂垃圾治理情况开展专项督查；（二）推进重点岸段海漂垃圾视频监控建设，以当地沿海沙滩旅游区、养殖区、码头等海漂垃圾较多的重点岸段为监控点，在每个岸段安装视频监控设备，实现了对重点岸段海域全景监控；（三）引入视频AI智能算法，对视频监控设备获取的岸线图像进行智能识别，提取海漂垃圾的类型、数量、位置等数据，形成台账，有效解决了海漂垃圾监管海域范围广、监管人力不足等问题；（四）利用太阳能发电、5G等技术，有效解决解决了偏远岸段强弱电施工难度大、成本高的问题。',
          imgUrl: '/img/case/case9.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //福建省危险废物智能化系统项目
        fjswxfw: {
          title: '企业环保',
          name: '福建省危险废物智能化系统项目',
          desc: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
          imgUrl: '/img/case/case10.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '泉州市晋江生态环境局生态云指挥中心',
              desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
              path: '/caseDetail/jjssty',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '水利视频AI示范应用',
              desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              path: '/caseDetail/slspaisf',
            },
          ],
        },
        //福建省“生态云“视频能力平台
        fjsstyspnl: {
          title: '核心能力',
          name: ' 福建省“生态云“视频能力平台',
          desc: '通过整合全省生态环境行业的视频资源，将散落在各地市局、区县局的视频资源联网汇聚纳入统一的视频平台管理，实现水、气、土、自然资源、海洋、固废、污染源、辐射源等视频资源的汇聚、治理和共享，以“微服务、组件化”的方式生态云“一张图”及其他业务系统。',
          imgUrl: '/img/case/case11.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '福建省生态环境厅海漂垃圾智能监管项目',
              desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
              path: '/caseDetail/hpljznjg',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '福建省危险废物智能化系统项目',
              desc: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              path: '/caseDetail/fjswxfw',
            },
          ],
        },
        //水利视频AI示范应用
        slspaisf: {
          title: '核心能力',
          name: '水利视频AI示范应用',
          desc: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
          imgUrl: '/img/case/case12.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '福建省生态环境厅海漂垃圾智能监管项目',
              desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
              path: '/caseDetail/hpljznjg',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '福建省危险废物智能化系统项目',
              desc: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              path: '/caseDetail/fjswxfw',
            },
          ],
        },
        //莆田市“全域数字化全市一张图”视频服务平台
        ptsyzt: {
          title: '核心能力',
          name: '莆田市“全域数字化全市一张图”视频服务平台',
          desc: '莆田市“全域数字化，全市一张图”统筹运用数字化技术、思维、能力，搭建大数据调度指挥平台，把数字化贯彻到莆田经济、政治、文化、社会、生态文明建设全过程各方面，对市域治理的模式进行全方位、系统性重塑，做到精准、科学、依法、高效，全面提升社会治理体系和治理能力现代。相关工作纳入了《2022 年数字福建工作要点》。其中，视频服务平台首创了城市级、多租户视频资源标签治理体系，与莆田市政务 0A 系统打通，实现申请、审批、调阅、共享全程留痕，助力政府视频安全、有序地共享。累计接入全市各部门 15多万路视频，实现跨部门视频共享共9万多路，累计开展视频标签治理 20多万次，形成标签数据3万多条，支撑了防汛防台风、木兰溪综合治理、防疫物资“一张图”、“党建引领、夯基惠民”、五联防“一张图”等专题应用。',
          imgUrl: '/img/case/case13.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '福建省生态环境厅海漂垃圾智能监管项目',
              desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
              path: '/caseDetail/hpljznjg',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '福建省危险废物智能化系统项目',
              desc: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              path: '/caseDetail/fjswxfw',
            },
          ],
        },
        //泉州市晋江生态环境局生态云指挥中心
        jjssty: {
          title: '核心能力',
          name: '泉州市晋江生态环境局生态云指挥中心',
          desc: '生态云指挥中心是泉州市晋江生态环境局运用先进科技手段开展精准治污的智慧指挥平台，可实现污染源自动监控、河道水质自动监控、污染源视频监控、企业用电监控、信访件办理调度等智能监管功能。生态云指挥中心运行以来，晋江生态环境保护执法方式进一步优化，执法效能进一步提升。2021年，先后利用各类管控数据办理了在线监控数据超标、数据造假、污染治理设施不正常运行等各种类型的非现场执法案件26起。其中，“利用烟气自动监控数据查处陶瓷企业超标排污案件”“运用‘河道哨兵’精准发现线索查处违法排放水污染物案”两个案件分别入选了生态环境部于2021年4月、10月通报的第一批、第四批优化执法方式的典型案例，并受到了通报表扬。生态云指挥中心“非现场执法”监管的经验做法及成效，得到福建省生态环境厅行文向各设区市推广学习，也受到晋江市委、市政府主要领导的批示肯定。',
          imgUrl: '/img/case/case14.png',
          moreCase: [
            {
              icon: '/img/solution/icon003.png',
              name: '福建省生态环境厅海漂垃圾智能监管项目',
              desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
              path: '/caseDetail/hpljznjg',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '福建省危险废物智能化系统项目',
              desc: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              path: '/caseDetail/fjswxfw',
            },
          ],
        },
      },
      moreCase: [
        {
          icon: '/img/case/case15.png',
          name: '漳浦县水库雨水情和安全设施监测系统',
          desc: '本项目建设以“统一规划、上下连接，讲求效益、注重应用”为原则，以水库雨水情和大坝安全监测物联网+视频AI算法智能分析管控为目标，基于现有设施资源的基础上，按照水利设施感知信息完善化、精细化、智能化的管理思路，让监管人员对水库的雨水情和安全监测监管，实现远程化监管应用，可视化决策分析',
          path: '/caseDetail/zpxsk',
          id: 'zpxsk'
        },
        {
          icon: '/img/case/case7.png',
          name: '福建省生态环境厅海漂垃圾智能监管项目',
          desc: '（一）将各类涉海航拍数据、监测数据一并纳入福建省生态云平台管理，全面掌握海漂垃圾产生和分布情况，做到涉海污染源实时“云”管控；（二）采用无人机对岸段进行巡航监测，获取海漂垃圾分布密度，评估治理成效；（三）在重点岸段布设高清摄像头，利用人工智能技术，对漂浮物进行监测、识别、分析和预警；（四）开发海漂垃圾治理调度App，每日向相关工作人员发送重点岸段海漂垃圾监测报告，为基层提供辖区内重点岸段海漂垃圾分布信息，服务基层日常作业。',
          path: '/caseDetail/hpljznjg',
          id: 'hpljznjg'
        },
      ],
    }
  },
  created() {
    let caseId = this.$route.params.id
    this.caseIntro = this.caseDetail[caseId]
  },
  mounted() {
  },
  methods: {
    goToCaseDetail(item) {
      this.caseIntro = this.caseDetail[item.id];
      this.$router.push(item.path)
    },
    /** 播放视频 **/
    play() {
      if (this.playing) {
        this.playing = false
        this.$refs.video.pause()
      } else {
        this.playing = true
        this.$refs.video.play()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/style.scss";
@import "/src/assets/css/util.scss";
.case-detail-box {
  padding: 24px 20px 32px 20px;
  box-sizing: border-box;
  background: #fff;
  .case-detail-title {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #333333;
    line-height: 22px;
  }
  .case-detail-desc {
    margin-top: 24px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    letter-spacing: 0.6px;
    word-break: break-all;
    text-indent: 2em;
  }
  .case-detail-img {
    margin-top: 24px;
    width: 100%;
  }
}
.more-case-box {
  padding-bottom: 20px;
  .more-case-title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #333333;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
  .more-case-card-item {
    margin-top: 12px;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    display: flex;
    background: #ffffff;
    border-radius: 4px;
    .more-case-card-item-img {
      width: 48px;
      height: 48px;
      background: #b6b6b6;
      border: 0.6px solid #eeeeee;
      border-radius: 24.6px;
      margin-top: 16px;
    }
    .more-case-card-item-box {
      padding: 20px 0 0 12px;
      box-sizing: border-box;
      .more-case-card-item-title {
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: $primary-text;
        line-height: 22px;
        @include text-ellipsis-line(1)
      }
      .more-case-card-item-desc {
        font-size: 12px;
        margin-top: 8px;
        color: rgba(51, 51, 51, 0.7);
        line-height: 20px;
        @include text-ellipsis-line(2)
      }
    }
  }
}
.aboutwf-video {
  position: relative;
  width: 100%;
  height: 200px;
  background: linear-gradient(270deg,#060e24, #000000);
  border-radius: 2px;
  .play-icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94px;
    height: 94px;
    @include bgConfig;
    background-size: 100% 100%;
    cursor: pointer;
    transition: all 0.3s ease-out;
    background-image: url('../../public/img/home/icon_play@2x.png');
    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}
</style>

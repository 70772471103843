const system = 'wanfu'   // wanfu 或者 nanwei
// const system = 'nanwei'   // wanfu 或者 nanwei


// 设置标题
windowTitle(system);
function windowTitle(system) {
    if (system === 'wanfu') {
        document.title = '福建万福信息技术有限公司'
    } else if (system === 'nanwei') {
        document.title = '南威数字生态'
    }
}

export {system}

<template>
  <div class="consultation-box">
    <div class="consultation-box-title">汇聚万福 智赢未来</div>
    <div class="consultation-box-title-sub">赋能环保水利 引领数智创新</div>
    <button class="consultation-box-btn mobile-btn" @click="goToContactWe">产品咨询</button>
  </div>
</template>

<script>
export default {
  name: "consultation",
  data () {
    return {

    }
  },
  mounted() {
  },
  methods: {
    goToContactWe() {
      this.$router.push('/contactWe')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/util.scss";
.consultation-box {
  position: relative;
  width: 100%;
  height: 160px;
  background: url("/img/consultation/bg03.png") no-repeat;
  background-size: 100% 100%;
  .consultation-box-title {
    position: absolute;
    top: 28px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
  .consultation-box-title-sub {
    position: absolute;
    top: 58px;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    letter-spacing: 0.38px;
  }
  .consultation-box-btn {
    position: absolute;
    top: 99px;
    left: calc(50% - 56px);
  }
}
</style>

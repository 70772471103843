import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter); //挂载属性

//创建路由对象并配置路由规则
let router = new VueRouter({
  routes: [
      //一个个对象
      { path: '/', name: 'layout',redirect: '/home' },
      {
          path: '/layout',
          name: '主页',
          component: () => import( /* webpackChunkName: "page" */ '@/layout/layout'),
          children: [
              {
                  path: '/home',
                  name: '首页',
                  component: () => import( /* webpackChunkName: "page" */ '@/views/home'),
              },
              {
                  path: '/solutions/:id',
                  name: '解决方案',
                  component: () =>
                      import( /* webpackChunkName: "page" */ '@/views/solutions'),
              },
              {
                  path: '/products/:id',
                  name: '核心产品',
                  component: () => import( /* webpackChunkName: "page" */ '@/views/products'),
              },
              {
                  path: '/hardware/:id',
                  name: '硬件产品',
                  component: () =>
                      import( /* webpackChunkName: "page" */ '@/views/hardware'),
              },
              {
                  path: '/case',
                  name: '项目案例',
                  component: () =>
                      import( /* webpackChunkName: "page" */ '@/views/case'),
              },
              {
                  path: '/caseDetail/:id',
                  name: '项目案例详情',
                  component: () =>
                      import( /* webpackChunkName: "page" */ '@/views/caseDetail'),
              },
              {
                  path: '/contactWe',
                  name: '联系我们',
                  component: () =>
                      import( /* webpackChunkName: "page" */ '@/views/contactWe'),
              },
          ]
      },
  ]
});
export default router;

<template>
  <div
      class="moduleWrap-box"
      :style="{backgroundImage: `url(${bgImg})`,backgroundColor: bgCol}"
  >
    <div class="moduleWrap-box-title" v-show="title" :style="{color: titCol}">{{title}}</div>
    <div class="moduleWrap-box-sub-title" v-show="subTitle" :style="{color: subCol}" v-html="subTitle"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "moduleWrap",
  props: {
    bgImg: {
      type: String,
      default: '',
    },
    bgCol: {
      type: String,
      default: '',
    },
    titCol: {
      type: String,
      default: '#333333',
    },
    subTitle: {
      type: String,
      default: '',
    },
    subCol: {
      type: String,
      default: '#929caa',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.moduleWrap-box {
  width: 100%;
  padding: 32px 20px;
  box-sizing: border-box;
  background-size: 100% 100%;
  .moduleWrap-box-title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #333333;
  }
  .moduleWrap-box-sub-title {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #929caa;
    line-height: 17px;
  }
}
</style>

<template>
  <div class="layout-box">
    <Header/>
    <div ref="router-box" class="router-box" :style="{height: `calc(100% - ${headerHeight}PX)`}" id="router-box">
      <router-view />
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from "./header";
import Footer from "./footer";
import {headerHeight} from '../config/layout_config'
export default {
  name: "layout",
  components: {
    Header,
    Footer
  },
  watch: {
    $route() {
      this.$refs['router-box'].scrollTop = 0 // 每次进入页面置顶
    }
  },
  mounted() {

  },
  data() {
    return {
      headerHeight,
    }
  },
}
</script>

<style lang="scss" scoped>
.layout-box {
  height: 100vh;
  width: 100vw;
  position: relative;
}
.router-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
</style>

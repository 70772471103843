<template>
  <div class="h5-box">
    <div class="products-banner">
      <div class="products-banner-title">{{selectProduct.swiperList[0].title}}</div>
      <div class="products-banner-sub-title">{{selectProduct.swiperList[0].subTitle}}<br>{{selectProduct.swiperList[0].abbTitle}}</div>
    </div>
    <div class="content">
      <div class="content-title">{{selectProduct.info.title}}</div>
      <div class="content-card">{{selectProduct.info.desc}}</div>
    </div>
    <ModuleWrap
      title="客户价值"
      bg-col="#fff"
    >
      <div class="client-box" v-if="refreshStyle">
        <div
            :class="selectProduct.clientList.length === (index + 1) && (selectProduct.clientList.length % 2 !== 0) ?'client-item client-item-full':'client-item'"
            v-for="(item,index) in selectProduct.clientList"
            :key="index"
        >
          <div class="client-item-img" :style="{background:`url(${item.bgUrl})`,'background-size':'cover'}">
            <div class="client-item-name">{{item.name}}</div>
            <div class="client-item-desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
      <!-- :style="{width: (selectProduct.clientList.length === (index + 1) && (selectProduct.clientList.length % 2 !== 0)) && '100%','min-height': (selectProduct.clientList.length === (index + 1) && (selectProduct.clientList.length % 2 !== 0)) && '136px'}" -->
    </ModuleWrap>
    <ModuleWrap
      title="方案优势"
      bg-img="/img/product/bg02.png"
    >
      <div class="plan-box">
        <div class="plan-item" v-for="(item,idx) in selectProduct.planList" :key="idx+10">
          <div class="plan-item-name">
            <p class="name">{{item.name}}</p>
            <div class="img">
              <img :src="item.icon" alt="">
            </div>
          </div>
          <div class="plan-item-desc">{{item.desc}}</div>
        </div>
      </div>
    </ModuleWrap>
    <div class="banner-box">
      <swiper style="height: 100%" :options="imgOption" class="img-swiper" ref="imgSwiper">
        <swiper-slide
          class="img-item"
          v-for="(item,ind) of selectProduct.imgList"
          :key="ind"
        >
          <div
            class="img-inner"
            :style="{backgroundImage: `url(${item.imgUrl})`}"
          ></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <Consultation />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Consultation from './consultation';
import ModuleWrap from "../components/moduleWrap";

export default {
  name: "products",
  components: {
    swiper,
    swiperSlide,
    Consultation,
    ModuleWrap
  },
  watch: {
    "$route.params.id": {
      handler(val, old){
        if(val!==old){
          let productId = val
          this.selectProduct = this.productData[productId]
          this.refreshStyle = false
          this.$nextTick(()=>{
            this.refreshStyle = true
          })
        }
      },
      deep: true
    }
  },
  data() {
    return {
      selectProduct: null,
      refreshStyle:true,
      productData: {
        //视频智能管理平台
        spzngl: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/product/banner03@2x.png',
              title: '核心能力',
              subTitle: '视频能力服务平台',
              abbTitle:'(VIMS)',
              btn: 'contact',
            },
          ],
          // 描述信息
          info: {
            title: '方案概述',
            desc:'VIMS 视频智能管理系统以构建城市级“视频资产档案”和“视频服务能力”为目标，通过“视频接入→视频描述→视频共享→视频检索→视频调阅”5 个步骤，实现各厂商设备和平台无缝接入、半自动化视频标签描述、多租户视频标签治理、免插件视频流输出、按监视对象检索视频、前端存储视频毫秒级调阅、申请审批共享调 阅全程0A 留痕等功能。助力城市“一张图”及各业务系统开发。产品已获得1项发明专利。4项计算机软件著作登记证书，通过国家安全防范报警系统产品质量检验检测中心、公安部安全与警用电子产品质量检测中心检验，并完成信创环境的适配。',
          },
          //客户价值
          clientList:[
            {
              bgUrl: '/img/product/bg_icon01.png',
              name: '联网整合，统筹规划',
              desc:'汇聚以视频为核心的物联感知数据，提供视频智能管理和服务'
            },
            {
              bgUrl: '/img/product/bg_icon02.png',
              name: '数据治理，挖掘分析',
              desc:'以视频数据为生产要素，以数据流驱动信息流、业务流、管理流的重塑'
            },
            {
              bgUrl: '/img/product/bg_icon03.png',
              name: '智能分析，普惠技术',
              desc:'基于计算机视觉技术对监控场景中的视频进行分析，提取关键信息'
            },
            {
              bgUrl: '/img/product/bg_icon04.png',
              name: '驱动应用，闭环业务',
              desc:'从场景化角度落地智能，将场景、机器、事件、人和机制等要素串联起来'
            }
          ],
          //方案优势
          planList:[
              {
                icon: '/img/product/icon_product01.png',
                name: '标准“引领”',
                desc: '为生态环境、水利等视频资源的布建、接入和各业务系统的调用提供统一标准流程'
              },
              {
                icon: '/img/product/icon_product02.png',
                name: '接入“融合”',
                desc: '支持不同厂商各类视频设备、视频平台全覆盖、自动化、标准化接入'
              },
              {
                icon: '/img/product/icon_product03.png',
                name: '数据“融合”',
                desc: '提供业务标签服务，实现数据灵活绑定，助力客户形成以“业务监管对象”为核心的视图大数据体系'
              },
              {
                icon: '/img/product/icon_product04.png',
                name: '业务“融合”',
                desc: '标准化接口，让政府业务应用在“视频”方面统一“着装”，差异化服务，确保各类监管和服务场景的有序开展'
              }
          ],
          //产品展示
          imgList: [
            {
              imgUrl: '/img/product/img_box04.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box05.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box15.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box16.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box17.png',
              name: '',
            }
          ],
        },
        //视频智能分析平台
        spznfx: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/product/banner03@2x.png',
              title: '核心能力',
              subTitle: '视频智能分析系统',
              abbTitle:'(EAIGo)',
              btn: 'contact',
            },
          ],
          // 描述信息
          info: {
            title: '方案概述',
            desc:'视频数据已然成为城市物联数据的主要来源，也是构建新型智慧城市感知体系最重要的数据资源。特别是在生态环境、水利领域，视频数据是辅助相关管理部门对水、气、土、海、污染源、辐射源、固危废等环境对象进行决策的重点要素。EAIGo视频智能分析系统将人工智能作为工具，提炼行业场景的特殊性，关注细分行业场景化应用，针对不同监视对象，建立自动化的“巡航-截图-回传-标注-存储”的行业视图数据集智能收集体系。以AI应用为锚，以“算法模型”为核心，持续推进AI与环保、水利行业深度融合，不断丰富和拓展AI新的行业应用场景，不断迈出人工智能行业应用实践布局新步伐。',
          },
          //客户价值
          clientList:[
            {
              bgUrl: '/img/product/bg_icon01.png',
              name: '增强防护',
              desc:'对视频内容进行实时检测和识别，有效提升监控系统的预警和反应速度'
            },
            {
              bgUrl: '/img/product/bg_icon02.png',
              name: '提高效率',
              desc:'快速处理大量的视频数据，提高信息检索和分析的效率'
            },
            {
              bgUrl: '/img/product/bg_icon03.png',
              name: '降低成本',
              desc:'优化算力资源使用率，实现AI的普惠化，从而降低运营成本'
            },
            {
              bgUrl: '/img/product/bg_icon04.png',
              name: '促进决策',
              desc:'通过视频智能分析系统能够提供大量有价值的刷视频分析数据，帮助决策者做出更为科学、合理的决策。'
            }
          ],
          //方案优势
          planList:[
              {
                icon: '/img/product/icon_product05.png',
                name: '数据',
                desc: '视频自动巡航管理，构建监视对象图像数据集收集体系，累计已收集达100多万张行业数据'
              },
              {
                icon: '/img/product/icon_product06.png',
                name: '算力',
                desc: '细分AI场景，小模型算法+多路轮询计算+低功耗助力算力资源投资节省50%以上'
              },
              {
                icon: '/img/product/icon_product07.png',
                name: '算法',
                desc: '大小模型协同进化构建AI算法模型有机循环的智能体系，实现单场景10张素材图片、2 小时即可训练生成模型、8 小时内快速调优模型'
              }
          ],
          //产品展示
          imgList: [
            {
              imgUrl: '/img/product/img_box06.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box07.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box08.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box09.png',
              name: '',
            }
          ],
        },
        //元物联平台
        ywl: {
          //产品概述
          swiperList: [
            {
              imgUrl: '/img/product/banner03@2x.png',
              title: '核心能力',
              subTitle: '元物联平台',
              abbTitle:'(UMIoT)',
              btn: 'contact',
            },
          ],
          // 描述信息
          info: {
            title: '方案概述',
            desc:'UMIoT元物联平台具备多项功能，为用户提供一站式的物联网解决方案。平台致力于水生态环境、海洋生态环境、污染源、固体废物、管网、管网设施、支流河库、水利工程设施等环保、水利场量的智能感知数据采集。实现雨量、水位、流量、液位、水质等水情数据，压力、渗压、渗流、位移、变形等工情数据，电压、电流、负荷等用电数据的采集，并通过规则引擎、数据智能引擎、业务模型引擎等实现时序数据过滤、解析和分发。平台可以实现站点管理，确保站点运行稳定、安全。网关管理功能使得网关与设备之间的连接更加稳定可靠，提高数据传输效率。通过设备监控功能实时监测设备运行状态，及时发现并解决设备故障，确保设备高效稳定运行。',
          },
           //客户价值
          clientList:[
            {
              bgUrl: '/img/product/bg_icon01.png',
              name: '统一纳管',
              desc:'支持站点、网关、传感等多种类型、多种品牌的设备接入，统一管理'
            },
            {
              bgUrl: '/img/product/bg_icon02.png',
              name: '提高效率',
              desc:'实时监控设备运行状态和各项数据，帮助企业快速发现设备故障和问题'
            },
            {
              bgUrl: '/img/product/bg_icon05.png',
              name: '降低成本',
              desc:'可以实现设备的远程监控和管理，减少现场维护和人工检查的成本'
            }
          ],
          //方案优势
          planList:[
              {
                icon: '/img/product/icon_product05.png',
                name: '数据质控',
                desc: '通过对数据进行格式、范围、唯一性等验证，确保数据的合规性，增强数据可用性，降低数据维护成本'
              },
              {
                icon: '/img/product/icon_product06.png',
                name: '灵活性强',
                desc: '根据用户场景实际需求自定义规则，实现对设备、数据的高效处理和分析'
              },
              {
                icon: '/img/product/icon_product07.png',
                name: '数据安全',
                desc: '采取多种安全措施，保护设备数据的安全和隐私。通过访问控制和身份认证等手段，防止非法访问和数据泄露'
              }
          ],
          //产品展示
          imgList: [
            {
              imgUrl: '/img/product/img_box10.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box11.png',
              name: '',
            },
            {
              imgUrl: '/img/product/img_box12.png',
              name: '',
            }
          ],
        },
      },
      imgOption: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        initialSlide: 0, //默认第几张
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        loop: false, //循环
        simulateTouch: true,
        autoplayDisableOnInteraction: false, //触摸后再次自动轮播
        autoplay: 2000, //每张播放时长3秒，自动播放
        speed: 1000, //滑动速度
      }
    }
  },
  created() {
    let solutionsId = this.$route.params.id
    this.selectProduct = this.productData[solutionsId]
  },
  mounted() {
    let solutionsId = this.$route.params.id
    this.selectProduct = this.productData[solutionsId]
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
.products-banner {
  width: 100%;
  height: 160px;
  background: url("/img/product/img_top_01.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .products-banner-title {
    position: absolute;
    left: 32px;
    top: 39px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: $primary-color;
    line-height: 20px;
  }
  .products-banner-sub-title {
    position: absolute;
    left: 32px;
    top: 68px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #333333;
    line-height: 28px;
  }
}
.content {
  width: 100%;
  background: #fafafb;
  padding: 32px 20px;
  box-sizing: border-box;
  .content-title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: $primary-text;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
  .content-card {
    margin-top: 16px;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.03);
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: $primary-text;
    line-height: 24px;
    text-indent: 2em;
    word-break: break-all;
    text-align: justify;
  }
}
.client-box {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  justify-content: flex-start;
  margin: 16px -6px -6px -6px;
  .client-item {
    padding: 2px 4px 2px 2px;
    box-sizing: border-box;
    margin: 0 6px;
    margin-top: 12px;
    width: calc(50% - 12px);
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 25px 0px rgba(216,222,228,0.50);
    .client-item-img {
      width: 100%;
      min-height: 180px;
    }
    .client-item-name{
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      color: $primary-text;
      line-height: 22px;
      letter-spacing: 0.02px;
      padding-top: 28px;
    }
    .client-item-desc {
      margin-top: 12px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      letter-spacing: 0.02px;
      text-align: justify;
      padding: 0 12px;
    }
  }
  .client-item-full {
    padding: 2px 4px 2px 2px;
    width: 100%;
    .client-item-img  {
      min-height: 136px;
    }
  }
}
.plan-box {
  margin-top: 16px;
  .plan-item {
    background-color: #ffffff;
    min-height: 112px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    .plan-item-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      padding: 16px 16px 4px 20px;
      .name {
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        letter-spacing: 0.02px;
        font-weight: 700;
      }
      .img {
        width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .plan-item-desc {
      font-size: 12px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      letter-spacing: 0.02px;
      padding: 0 20px 20px;
      text-align: justify;
    }
  }
}
.banner-box {
  width: 100%;
  min-height: 270px;
  background: url("/img/product/bg01.png") no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  box-sizing: border-box;
  .img-swiper{
    overflow: visible;
    .swiper-pagination{
      bottom: -34px;
    }
    .img-inner {
      width: 100%;
      height: 180px;
      background-color: #ddd;
      background-size: 100% 100%;
      margin: 16px 0 0 0;
      border: 4px solid #1d283d;
      border-radius: 2px;
    }
  }
  ::v-deep .swiper-pagination-bullet{
    width: 40px;
    height: 2px;
    border-radius: 0;
    background: #8b99b2;;
    margin: 0;
    &.swiper-pagination-bullet-active{
      background: #ffffff;
    }
  }
}
</style>

/** menu菜单 **/
export const menuList = [
    // {
    //     path: '/home',
    //     name: '首页',
    // },
    {
        path: '/solutions',
        name: '解决方案',
        isTitle: true,
        children: [
            {
                name: '水环境',
                children: [
                    {
                        name: '水环境综合管理平台',
                        path: '/solutions/szyzhgl',
                        status: ''
                    },
                    {
                        name: '河湖"四乱"综合整治',
                        path: '/solutions/hhslzl',
                        status: ''
                    },
                    {
                        name: '城市污水管网智慧监管',
                        path: '/solutions/wsgwzhjg',
                        status: 'NEW'
                    },
                    {
                        name: '农村生活污水智慧监管',
                        path: '/solutions/ncwszhjg',
                        status: ''
                    },
                    {
                        name: '海洋环境综合管理平台',
                        path: '/solutions/hpljzl',
                        status: ''
                    },
                ]
            },
            {
                name: '水工程',
                children: [
                    {
                        name: '智慧水库管理系统',
                        path: '/solutions/zhskgl',
                        status: 'HOT'
                    },
                    {
                        name: '灌区信息化系统',
                        path: '/solutions/gqxxhgl',
                        status: ''
                    },
                ]
            },
            {
                name: '水资源',
                children: [
                    {
                        name: '城乡供水一体化',
                        path: '/solutions/cxgsyth',
                        status: ''
                    },
                ]
            },
            {
                name: '企业环保',
                children: [
                    {
                        name: '固体废物和危险化学品管理',
                        path: '/solutions/gwfqlc',
                        status: ''
                    },
                ]
            },
        ]
    },
    {
        path: '/products',
        name: '核心能力',
        isTitle: false,
        children: [
            {
                name: 'VIMS视频能力服务平台',
                code: 'vims',
                path: '/products/spzngl',
                status: 'HOT',
            },
            {
                name: 'UMIoT元物联平台',
                code: 'umiot',
                path: '/products/ywl',
                status: ''
            },
            {
                name: 'EAIGo视频智能分析系统',
                code: 'eaigo',
                path: '/products/spznfx',
                status: ''
            },
        ]
    },
    {
        path: '/hardware',
        name: '硬件产品',
        isTitle: true,
        children: [
            {
                name:'视频边缘计算系列',
                children:[
                    {
                        name: 'EAI智算精灵',
                        code: 'eaigo',
                        path: '/hardware/eaigo',
                        status: '',
                    },
                    {
                        name: '存算一体VGIS-N7100',
                        code: 'csyt',
                        path: '/hardware/csyt',
                        status: '',
                    }
                ]
            },
            {
                name:'物联网关系列',
                children:[
                    {
                        name: '遥测终端机',
                        code: 'rtu',
                        path: '/hardware/rtu',
                        status: '',
                    }
                ]
            },
            {
                name:'振弦采集系列',
                children:[
                    {
                        name: '多通道振弦采集仪',
                        code: 'dtdzxcjy',
                        path: '/hardware/dtdzxcjy',
                        status: '',
                    },
                ]
            },
            {
                name:'前端传感器系列',
                children:[
                    {
                        name: '雨量计',
                        path: '/hardware/hyetometer',
                        code: 'hyetometer',
                        status: ''
                      },
                      {
                        name: '气泡式水位计',
                        path: '/hardware/qpsswj',
                        code: 'qpsswj',
                        status: ''
                      },
                      {
                        name: '雷达水位计',
                        path: '/hardware/ldswj',
                        status: '',
                        code: 'ldswj',
                      },
                      {
                        name: '振弦式渗压计',
                        path: '/hardware/zxssyj',
                        code: 'zxssyj',
                        status: ''
                      },
                      {
                        name: '磁致式量水堰计',
                        path: '/hardware/czslsyj',
                        code: 'czslsyj',
                        status: ''
                      },
                      {
                        name: '流量计',
                        path: '/hardware/flowmeter',
                        code: 'flowmeter',
                        status: ''
                      },
                      {
                        name: '表面变形监测',
                        path: '/hardware/bmbxjc',
                        code: 'bmbxjc',
                        status: ''
                      }
                ]
            },
            {
                name:'其他',
                children:[
                    {
                        name: '水利一杆通WF-JC100',
                        code: 'slygt',
                        path: '/hardware/slygt',
                        status: ''
                    },
                ]
            }  
        ]
    },
    {
        path: '/case',
        name: '项目案例',
    },
    {
        path: '/contactWe',
        name: '联系我们',
    },
]


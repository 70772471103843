import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import 'lib-flexible'
import './assets/css/index.scss'
Vue.config.productionTip = false

new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')

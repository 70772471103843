<template>
  <div id="app" class="app">
    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import {system} from "@/config/system_type";
import axios from 'axios';
import wx from "weixin-js-sdk";
import FastClick from 'fastclick';
export default {
  name: 'App',
  components: {
  },
  created() {
    this.getShareInfo();
    FastClick.attach(document.body)
  },
  mounted() {
  },
  methods: {
    getShareInfo() {
      const url = window.location.href
      axios.get('http://47.122.43.251:5000/api/signature?url=' + url).then((res)=>{
        const data = {
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
        }
        wx.config({
          debug: false,
          ...data,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData"
          ] // 必填，需要使用的 JS 接口列表
        });
        wx.ready(() => {
          let shareData = {}
          if (system === 'wanfu') {
            shareData = {
              title: "福建万福信息技术有限公司",
              desc: "赋能环保水利，引领数智创新",
              link: window.location.href,
              imgUrl: window.location.origin + "/img/weixin/wanfu_img.png",
              success: function () {
                console.log('设置成功！')
              }
            };
          } else if (system === 'nanwei') {
            shareData = {
              title: "南威智慧生态",
              desc: "赋能环保水利，引领数智创新",
              link: window.location.href,
              imgUrl: window.location.origin + "/img/weixin/nanwei_img.png",
              success: function () {
                console.log('设置成功！')
              }
            };
          }
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          wx.updateAppMessageShareData(shareData)
          //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(shareData);
        });
        //错误了会走 这里
        wx.error(function (res) {
          console.log("微信分享错误信息", res);
        });
      })
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
body {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}
</style>

<template>
  <div class="footer-box">
    <div class="footer-menu-box">
      <div
          class="footer-menu-box-item"
          v-for="(item,index) in menuList"
          :key="index"
      >
        <div class="footer-menu-box-item-text" @click="onClickFirstLevelMenu(item,index)">
          {{item.name}}
          <img
              v-if="item.children"
              :src="openMenu === item.path ? '/img/layout/footer/up.png' : '/img/layout/footer/down.png'"
              alt=""
          >
        </div>
        <div v-if="item.children && openMenu === item.path" class="footer-second-menu-box">
          <!-- 有标题-->
          <div v-if="menuSecondLevelList.isTitle">
            <div v-for="(item,index) in menuSecondLevelList.children" :key="index">
              <div class="footer-second-menu-box-item-title">{{item.name}}</div>
              <div
                  class="footer-second-menu-box-item"
                  v-for="items in item.children"
                  :key="items.name"
                  @click="onClickSecondLevelMenu(items)"
              >{{items.name}}</div>
              <div v-if="index + 1 < menuSecondLevelList.children.length" class="footer-second-menu-box-item-line"></div>
            </div>
          </div>
          <!-- 无标题-->
          <div v-else>
            <div
                v-for="(item,index) in menuSecondLevelList.children"
                :key="index"
                class="footer-second-menu-box-item"
                @click="onClickSecondLevelMenu(item)"
            >
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-info-box">
      <div class="company-info-text">
        <div class="company-info-text-item">
          <img src="/img/layout/footer/icon_fax.png" alt="">
          <div>lxudong2@linewell.com</div>
        </div>
        <div class="company-info-text-item">
          <img src="/img/layout/footer/icon_phone.png" alt="">
          <div>0591-87721622</div>
        </div>
        <div class="company-info-text-item">
          <img src="/img/layout/footer/icon_address.png" alt="">
          <div>福州市闽侯县万福中心1号楼21层</div>
        </div>
      </div>
      <img class="company-info-code" src="/img/layout/footer/code.jpg" alt="">
    </div>
    <div class="footer-bottom-box">
      版权所有©福建万福信息技术有限公司
      <div @click="linkTo" class="bei">闽ICP备 18017881 号 -1</div>
    </div>
  </div>
</template>

<script>
import {menuList} from "../mock_data/menu_data";
export default {
  name: "layout-footer",
  data() {
    return {
      menuList,
      openMenu: null,
      menuSecondLevelList: null
    }
  },
  mounted() {
  },
  methods: {
    /** 点击菜单 **/
    onClickFirstLevelMenu(item) {
      console.log(item)
      if (item.children && item.children.length > 0) {
        if (item.path === this.openMenu) {
          this.openMenu = null
        } else {
          this.menuSecondLevelList = item
          this.openMenu = item.path
        }
      } else {
        if (item.path !== this.$router.history.current.path) {
          this.$router.push(item.path);
        }
        this.openMenu = null;
      }
    },
    /** 点击二级菜单 **/
    onClickSecondLevelMenu(item) {
      if (item.path !== this.$router.history.current.path) {
        this.$router.push(item.path);
      }
      this.openMenu = null;
    },
    linkTo(){
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
.footer-box {
  width: 100%;
  height: auto;
  background: #111820;
}
.footer-menu-box {
  .footer-menu-box-item-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    position: relative;
    font-size: 14px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
    img {
      width: 12px;
      height: 12px;
    }
    &:active {
      color: $primary-color
    }
  }
  .footer-menu-box-item {
    position: relative;
    &:before {
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      content: '';
      background: #262B34
    }
  }
  .footer-menu-box-item:last-child:before {
    background: transparent;
  }
  .footer-second-menu-box {
    background: #06090D;
    padding: 16px 0;
    box-sizing: border-box;
    .footer-second-menu-box-item-title {
      position: relative;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 40px;
    }
    .footer-second-menu-box-item {
      position: relative;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 38px;
      box-sizing: border-box;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.60);
      letter-spacing: 0.2px;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        left: 20px;
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.60);
        border-radius: 50%;
      }
      &:active {
        color: $primary-color
      }
    }
    .footer-second-menu-box-item-line {
      position: relative;
      height: 33px;
      width: 100%;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        left: 20px;
        width: calc(100% - 40px);
        height: 1px;
        background: #262b34;
      }
    }
  }
}
.company-info-box {
  position: relative;
  height: 120px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .company-info-text {
    height: 100%;
    color: #fff;
    .company-info-text-item {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }
  .company-info-code {
    width: 80px;
    height: 80px;
  }
}
.footer-bottom-box {
  text-align: center;
  font-size: 10px;
  margin: 0 20px;
  padding: 10px 0;
  box-sizing: border-box;
  color: #fff;
  border-top: 1px solid #262B34;
  .line {
    margin: 0 8px;
    width: 1px;
    height: 14px;
    display: inline-block;
    background: hsla(0,0%,100%,.7);
  }
}
</style>

<template>
  <div class="h5-box">
    <div class="solutions-banner">
      <div class="solutions-banner-title">{{selectSolutions.swiperList[0].title}}</div>
      <div class="solutions-banner-sub-title">{{selectSolutions.swiperList[0].subTitle}}</div>
    </div>
<!--    详情-->
    <div class="solutions-desc">
      <div class="solutions-desc-card">
        {{selectSolutions.summaryData.inner}}
      </div>
    </div>
<!--  核心优势   -->
    <ModuleWrap
      title="核心优势"
      bg-col="#fff"
    >
      <div class="advantage-box">
        <div
            class="advantage-item"
            v-for="(item,index) in selectSolutions.advantageData"
            :key="item.name"
            :style="{width: (selectSolutions.advantageData.length === (index + 1) && (selectSolutions.advantageData.length % 2 !== 0)) && '100%'}"
        >
          <img class="advantage-item-img" :src="`/img/solution/icon0${index + 1}.png`" alt="">
          <div class="advantage-item-name">{{item.name}}</div>
          <div class="advantage-item-desc">{{item.desc}}</div>
        </div>
      </div>
    </ModuleWrap>
<!--    产品咨询 -->
    <Consultation />
  </div>
</template>

<script>
import ModuleWrap from "../components/moduleWrap";
import Consultation from "./consultation";
export default {
  name: "solutions",
  components: {
    ModuleWrap,
    Consultation,
  },
  watch: {
    "$route.params.id": {
      handler(val, old){
        if(val!==old){
          let solutionsId = val
          this.selectSolutions = this.solutionsData[solutionsId]
        }
      },
      deep: true
    }
  },
  data() {
    return {
      selectSolutions: {},
      solutionsData: {
        //水库雨水情测报和大坝安全监测解决方案
        zhskgl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top01.png',
              title: '水工程',
              subTitle: '智慧水库管理系统',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '近年来，《水利部关于进一步加强水库大坝安全管理的意见》、《农田水利条例》、《加快推进新时代水利现代化的指导意见》，《水利部办公厅关于报送“十四五”小型水库雨水情测报和安全监测设施实施方案以及 2021 年度实施计划的通知》等相关政策性文件的出台，明确指出了<span style="color:#0B68BF">要加强水库运行管护、风险管控、隐患排查治理、创新管理模式，实现水库工作的现代化管理，信息化管理，数字化管理，是落实国家政策要求，在水库运行管理中的创新性举措，具有十分重要的现实意义。</span>以“天天安全、百分之百安全”的目标要求，紧紧围绕水库安全管理的各项工作，利用云计算、大数据、物联网、移动互联网、GIS/RS、三维仿真、水利专业模型等现代先进技术，搭建水库综合管理云平台，为水库管理的“三个责任人”提供信息服务、监测分析及巡查管理服务。为落实“三个重点环节”提供数据支撑和应用服务，为水库的安全运行提供信息化管理手段。对水库下泄生态流量、水位、降雨量进行监管，远程监测水库下泄生态流量、水位、降雨量，以保证水库下游生态环境的和谐发展。',
            //方案概述
            inner: '智慧水库管理系统以“天天安全、百分之百安全”的目标要求，紧紧围绕水库安全管理的各项工作，利用物联网、云计算、大数据、人工智能、三维仿真、水利专业模型等跨学科现代先进技术，搭建区域水库综合管理平台，为水库管理的“三个责任人”提供档案数据归整、巡查自动记录、水工情智能监测、数据智能模拟、报告自动生成和问题闭环管理等服务。为落实“三个重点环节”提供数据支撑和应用服务，为水库的安全运行提供信息化管理手段。对水库水位、雨量、生态下泄流量进行远程监测和监管，确保水库洪涝灾害的预防和下游生态环境的和谐发展。',
            imgUrl: '/img/solution/img_frame01.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '设施智能互联',
              desc: '国标协议，支持不同品牌型号的物联网、视频设备接入；支持气象、环保等外委办局监测数据接入；支持人工监测数据录入和导入',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '视频AI联动',
              desc: '通过前端视频监控，结合计算机视觉AI算法，实现现场情况自动化联动录制以及水尺信息自动读取，辅助确认现场监测情况',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '库容模拟深度算法',
              desc: '针对暴雨来临前、中、后三种情景，可通过专业的计算模型，进行在线分析计算，为洪涝灾害预防提供科学辅助',
            },
            {
              icon: '/img/solution/icon004.png',
              name: '自动告警',
              desc: '支持灵活配置告警规则，支持根据不同的大坝类型设置不同告警规则，并自动产生告警并向相关责任人发送报警信息',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '水库雨水情在线测报平台',
              desc: '通过前端物联设备实现实时雨情、水情情况的自动化采集，同时利用深度算法，基于历史水位、雨量、流量信息，对水库的水位、库容量、蓄水量进行进行模拟预测。',
              imgUrl: '/img/solution/icon05@2x.png',
              path: '/products/skysq'
            },
            {
              name: '大坝安全监测平台',
              desc: ' 通过对大坝表面变形、渗流、渗压等异常情况进行自动监测，通过建立水位和位移、渗流量、浸润线的对应关系，结合人工智能算法模型分析大坝安全情况，对大坝的安全情况自动生成报告及评级管理，助力水库大坝安全智能监管。',
              imgUrl: '/img/solution/icon06@2x.png',
              path: '/products/skdbaq'
            },
            {
              name: '智算精灵',
              desc: '边缘智算系列产品是针对泛自然资源领域定制的数据、算法和算力“三融合”的视频智能分析终端，产品可集成我司自主研发的高精度深度学习算法。',
              imgUrl: '/img/solution/icon07@2x.png',
              path: '/pro_zhwl/zsjl'
            },
            {
              name: '水库监测物联网设备',
              desc: '利用雨量计、水位计、渗压计、GNSS等传感器设备采集水库实时监测信息，通过智慧网关传输到云平台，结合人工智能算法模型分析水库运行情况。',
              imgUrl: '/img/solution/icon08@2x.png',
              path: '/pro_zhwl/ywl'
            },
          ],
          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '水库日常管理',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '水环境管理',
              width: 1200
            },
          ],
        },
        //河湖四乱综合治理解决方案
        hhslzl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '水环境',
              subTitle: '河湖“四乱”综合整治',
              btn: 'contact',
            },
          ],
          summaryData: {
            desc: '<p>2020年，水利部办公厅发布《水利部办公厅关于深入推进河湖“清四乱”常态化规范化的通知》提出河湖“清四乱”是推动河长制湖长制“有名”“有实”的第一抓手，要深入推进河湖“清四乱”常态化、规范化，持续改善河湖面貌, 整治范围由大江大河大湖向中小河流、农村河湖延伸,实现河湖全覆盖。</p><p>地方各级河长制办公室、水行政主管部门以及各流域管理机构要将信息化建设作为重要任务、纳入重要日程，充分利用卫星遥感、视频监控、无人机、APP等技术手段加强河湖管护，提高河湖监管的信息化、现代化水平。</p>',
            inner:'利用视觉AI技术有效提取河道、湖泊等水环境要素特征，智能识别“乱建”、“乱占”、“乱堆”、“乱采”四乱问题并联动取证，实现无人值守全天候监控预警。具备水域岸线违章检测、河道漂浮物检测、倾倒垃圾监测、非法采砂船识别等多种涉水对象检测-识别-分类能力，构建以视图为核心的“发现问题-问题取证-派发处置-治理成效分析”全链式在线管理体系，服务河湖长制。',
            imgUrl: '/img/solution/img_frame02.png',
          },
          advantageData: [
            {
              icon: '/img/solution/icon005.png',
              name: '统一标准规范',
              desc: '提供建设标准规范，合理布设监满足不同功能需求的视频监控设备，打造共享共用的河湖视频监控“一张网“。',
            },
            {
              icon: '/img/solution/icon006.png',
              name: '整合视频资源',
              desc: '整合跨时期、多厂商建设的频监控设备、无人机等硬件设备，有效唤醒视频资源，实现实时监控和异常情况下自动提取视频资源和影像资料并保存。',
            },
            {
              icon: '/img/solution/icon007.png',
              name: '人工智能算法',
              desc: '提供水域岸线违章检测、河道漂浮物检测、倾倒垃圾、非法采砂船识别等多种人工智能算法，实时监测“四乱”问题，降本增效，加强河湖管护。',
            },
            {
              icon: '/img/solution/icon008.png',
              name: '智慧河湖监管',
              desc: '提供领导视角、管理视角、经办视角等不同角色的应用功能，满足用户在不同场景下的动态管理，提高河湖监管的信息化、现代化水平。',
            },
          ],
          relatedData: [
            {
              name: '治理效果可视化',
              desc: '通过“实时监测-智能预警-派发处置-定期调度”的全流程闭环监管运营模式，将治理的结果进行直观的对比展示，形成“统一调度、协同高效”的河湖四乱综合常态化体系，有效支撑业务管理和综合决策。',
              imgUrl: '/img/solution/icon01@2x.png',
            },
            {
              name: 'AI视频人工智能分析',
              desc: '实时监控、智能识别“乱建”、“乱占”、“乱堆”、“乱采”四乱问题并联动取证，实现无人值守全天候监控预警。',
              imgUrl: '/img/solution/icon02@2x.png',
            },
            {
              name: '工作台+考核管理',
              desc: '针对智能识别出的河湖“四乱”的问题线索，系统平台设置了实时监测、告警、推送、办理、反馈、调度的闭环工作流程，并分问题类型限定办理时限，做到问题快速核处和全流程可溯。',
              imgUrl: '/img/solution/icon03@2x.png',
            },
            {
              name: '智慧河长APP',
              desc: '发现“四乱”问题后，系统会自动生成现场任务，并通过电脑或手机APP推送给水利部门相关人员。接到线索后，工作人员现场通过移动应用，实现日常巡查、问题处置工作，大大提高了工作的效率。',
              imgUrl: '/img/solution/icon04@2x.png',
            },
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img09@2x.png',
              icon: '/img/product/icon08@2x.png',
              name: '水环境治理',
            },

            {
              imgUrl: '/img/product/img08@2x.png',
              icon: '/img/product/icon07@2x.png',
              name: '水资源保护',
              width:384
            },
            {
              imgUrl: '/img/product/img07@2x.png',
              icon: '/img/product/icon05@2x.png',
              name: '河湖清“四乱”',
              width:384
            },
            {
              imgUrl: '/img/product/img06@2x.png',
              icon: '/img/product/icon06@2x.png',
              name: '河长制管理',
              width:384
            },
          ],
        },
        //山洪灾害防御指挥系统解决方案
        shzhfy: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '智慧水利解决方案',
              subTitle: '山洪灾害防御指挥系统',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '2021年水利部印发了《全国山洪灾害防治项目实施方案（2021-2023年）》，其建设目标：到2023年，山洪灾害防治体系进一步健全，监测预警能力进一步提升，努力补齐山洪灾害防治当前存在的明显短板，最大程度减少人员伤亡和财产损失。',
            //方案概述
            inner:
                '山洪灾害防御指挥系统是一套具备完善预测、预报、预警能力和应急指挥能力的系统。系统主要包括水雨情监测系统、预警广播系统系统组成。通过采集终端对各项水文数据进行实时监测，并将采集数据通过无线通信终端传到中心的山洪灾害监测预警平台，平台对所得的数据进行综合分析并得出预警信息，最终由减灾发布系统发布预警信息。提高山洪灾害监测预警预报能力和应急救灾快速反应能力。',
            imgUrl: '/img/solution/img_frame03.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon009.png',
              name: '雨水情智能分析',
              desc: '对实时水位、流量、雨量走势分析，并提供历年雨情情对比分析功能，全面剖析雨水情状况。',
            },
            {
              icon: '/img/solution/icon010.png',
              name: '预警模式全方位',
              desc: '多种预警模式、多种预警等级应对不同山洪灾害情况，为保护人民的生命财产提供极大的保障。',
            },
            {
              icon: '/img/solution/icon011.png',
              name: '预警发布全覆盖',
              desc: '预警广播系统通过平台、短信、无线广播等方式发布预警公告，从而使得接收预警的区域人员能根据山洪灾害防御预案，及时采取预防措施，最大限度地减少人员伤亡。',
            },
            {
              icon: '/img/solution/icon012.png',
              name: '应急指挥信息化',
              desc: '可通过视频会议系统、移动式应急指挥系统实现信息的互联互通和人员及物资的统一调配。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '山洪灾害防御指挥平台',
              desc: '结合降雨雷达观测系统、实时监测降雨数据和气象精细化短临降雨预报数据为驱动，并结合遥感观测、动态预警分析等先进技术，实现山区小流域短历时洪水快速预报预警，有效提升山洪预警的时效性和精确性，保障工程运行管理安全，有效支撑山洪防控决策。',
              imgUrl: '/img/solution/icon09@2x.png',
            },
            {
              name: '山洪灾害监测物联网设备',
              desc: '运用水位计、雨量计、卫星遥感、广播系统、摄像头等感知设备，监测雨水情、动态预警分析山洪灾害情况、快速预报预警，提高山洪灾害监测预警预报能力和应急救灾快速反应能力。',
              imgUrl: '/img/solution/icon10@2x.png',
            },
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img13@2x.png',
              icon: '/img/product/icon04@2x.png',
              name: '山洪防汛',
            },
            {
              imgUrl: '/img/product/img12@2x.png',
              icon: '/img/product/icon11@2x.png',
              name: '雨水情监测',
              width: 384,
            },
            {
              imgUrl: '/img/product/img11@2x.png',
              icon: '/img/product/icon09@2x.png',
              name: '预警管理',
              width: 384,
            },
            {
              imgUrl: '/img/product/img10@2x.png',
              icon: '/img/product/icon10@2x.png',
              name: '应急管理',
              width: 384,
            },
          ],
        },
        //水资源综合管理解决方案
        szyzhgl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '水环境',
              subTitle: '水环境综合管理平台',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '近年来，国家发布了一系列政策，促进水环境治理行业发展。我国全方位推进生态环境保护和水污染治理，产业规模持续扩大。《“十四五”生态环境保护监管规划》、《水污染防治行动计划》等政策文件从顶层设计和国家规划层面全面推进生态环境治理以及水环境治理，推动概算水环境质量，努力修复水生态，保障水安全。',
            //方案概述
            inner:'以改善区域水生态环境质量为核心，以全面支撑水环境质量持续改善目标。充分运用物联网、视觉AI、数据智能等智能技术手段，集成整合区域水环境数据资源，围绕重点断面水质考核、功能区水质达标、跨界断面考核、流域生态补偿、水污染溯源分析、水环境整治等核心主题形成大数据分析能力，全面支撑水环境质量持续改善管理目标的实现。',
            imgUrl: '/img/solution/img_frame12.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '“全”场景',
              desc: '覆盖海域、河道、水面、排污口、岸线、水库、溢洪道、水闸、水土保持、在建水工程等水环境治理全场景。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '“全”智能',
              desc: '集成十余种行业专属的高精度深度学习算法，可实现人员、船只、物品、行为等全面智能识别告警。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '“全”业务',
              desc: '为河道四乱整治、水体污染、岸线监管、水库管理、水葫芦治理等务等完整闭环提供边缘端智能感知能力。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '水环境综合管理平台',
              desc: '水环境综合管理平台汇集涉水大数据，构建水环境智能应用，建成功能完备的水环境业务应用体系，支撑形成水环境开发利用管控、全流程业务联动。通过“数字赋能”为现代水网体系实现“多源互补、丰枯调剂、环境优美”提供有力支撑与强力驱动。',
              imgUrl: '/img/solution/icon09@2x.png',
              path: '/products/szyzhgl'
            },
            {
              name: '水环境监测物联网设备',
              desc: '水环境综合管理系统主要利用感知网络和视频监控等设施、结合视频AI智能分析技术实现用于对全区域内各个水环境的实时情况展示和监控并取证，及时了解水质环境动态，及时有效的发现问题、解决问题。',
              imgUrl: '/img/solution/icon10@2x.png',
            },
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '智慧水库',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img03@2x.png',
              icon: '/img/product/icon03@2x.png',
              name: '应急调度',
            },
            {
              imgUrl: '/img/product/img04@2x.png',
              icon: '/img/product/icon04@2x.png',
              name: '防汛指挥',
            },
          ],
        },
        //城乡供水一体化解决方案
        cxgsyth: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top04.png',
              title: '水资源',
              subTitle: '城乡供水一体化',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>2022年水利部印发《关于实施国家水网重大工程的指导意见》，明确了加快推进国家水网重大工程建设的主要目标，并提出了到2025年，城乡供水保障水平进一步提高，农村自来水普及率达到88%的要求。</p><p>福建颁布《福建省城乡供水条例》从法律层面提出推进区域联网，城乡统筹供水，以满足城乡居民对美好生活向往的需要为目标，着力构建从“水源头”到“水龙头”安全可靠、保质保量的城乡供水工程和管理体系。</p>',
            //方案概述
            inner:'利用云计算、大数据、物联网技术构建城乡供水一体化解决方案，实现厂站供水、排水、用水用户、水管监测设备数据融合，全面提升城市供水安全、生产管理、监控调度、管网管理、表务管理、决策分析能力，保障城乡供水安全、节水效率和服务质量。',
            imgUrl: '/img/solution/img_frame04.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '智慧管网，张弛有度',
              desc: '能实现城乡供水管网数据的智慧化管理，可根据实时的监测情况进行分析处理，用数字化的形式强化对现场的掌握，实现自动预警，处置起来更加张弛有度，有的放矢。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '管网体系，智能调度',
              desc: '通过系统的建设，提升了管网的精细化、信息化水平，形成管网体系化管理。根据管网的运行状态监测和分布状况，实现供水调配的智能调度。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '分区计量，漏损分析',
              desc: '利用管网分区计量数据及管网已有数据，进行数据分析、挖掘、对比系统，构建管网漏损分析系统，实现漏损分析，为城乡供水一体化平台提供数据支撑。',
            },
            {
              icon: '/img/solution/icon004.png',
              name: '多屏应用，便捷高效',
              desc: '通过供水一张图、电脑、移动APP的方式提升了管理的便捷度，做到数据实时监控，异常实时预警，漏损及时发现，故障及时派单，极大提高了管网管理、维护的工作效率。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '城乡供水一体化平台',
              desc: '平台充分运用“互联网+”手段，着力提升城乡供水领域数字治水的能力和水平，使供水工程“全域覆盖、全网共享、全时可用、全程可控”成为可能，打造了城乡供水统筹谋划、一体推进、均等服务、全民受益的高质量发展新格局。',
              imgUrl: '/img/solution/icon05@2x.png',
            },
            {
              name: '城乡供水物联网监测设备',
              desc: '城乡供水物联网监测设备主要有温湿度、液位、压力、流量等监测设备，同时采用摄像机、智能摄像机、NVR等视频监控设备，实时感知城乡供水系统的运行状态，实现对城乡供水工程远程监控、在线监测、实时预警、智慧监管。',
              imgUrl: '/img/solution/icon06@2x.png',
            }
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '智慧水务',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img04@2x.png',
              icon: '/img/product/icon04@2x.png',
              name: '智慧供水',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '农村饮用水管网',
            },
          ],
        },
        //灌区信息化管理解决方案
        gqxxhgl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top01.png',
              title: '水工程',
              subTitle: '灌区信息化系统',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>2021年1月，水利部办公厅和财政部办公厅联合印发《全国中型灌区续建配套与节水改造实施方案（2021—2022年）》，明确未来两年将对461处中型灌区实施改造，主要包括渠首工程、骨干输配水（含与高标准农田项目区连接渠道）和排水工程、骨干渠（沟）系建筑物及配套设施（用水量测、管理设施）、灌区信息化等内容。</p><p>同年11月11日，水利部办公厅印发了《中型灌区续建配套与节水改造项目建设管理办法（试行）》，进一步规范中型灌区续建配套与节水改造项目的建设管理。</p>',
            //方案概述
            inner:'灌区信息化系统通过在总干渠及干渠补充建设量测水监测设施、视频监视设备以及闸门控制设备，建立健全完善的通信传输体系以及供电体系，实现总干渠及干渠的信息采集自动化、通信传输网络化；通过在水利局建设灌区数据中心、应用支撑系统，实现灌区数据管理规范化、预警预报模型化；开发灌区管控一张图、闸门监控系统、视频监视系统、水量调度管理系统、综合信息监测管理系统、水量计量与水费计收管理系统、工程运维管理系统以及综合办公管理系统，实现灌区决策分析智能化。灌区信息化系统充分发挥灌溉效益，提高灌溉水利用系数，为灌区水资源的优化配置、高效利用、调度运行提供决策支持，同时变被动服务为主动服务，吸引公众参与。',
            imgUrl: '/img/solution/img_frame05.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '远程运维',
              desc: '监控软件支撑电脑、手机APP远程访问，方便供/用水管理和系统维护。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '科学配水',
              desc: '支撑制订灌溉用水计划和配水计划，并提供水量供需平衡分析。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '精准计费',
              desc: '支持水价分档管理、水费征收与结算等查询与统计。',
            },
            {
              icon: '/img/solution/icon004.png',
              name: '管控全面',
              desc: '提供渠道、水闸、泵站等工程基础及运行信息的统计报表及专题图展示。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '灌区信息化管理平台',
              desc: '灌区信息化管理平台主要包括水情信息采集与监测、闸门自动控制、水闸和渠道安全监测、视频监控、应急调度、灌溉水资源配置模型与用水管理决策支持、远程WEB查询等组成。该系统的应用能确保灌区的管理的安全运行，提高管理人员效率，提高水资源优化配置及用水效率发挥重要的作用。',
              imgUrl: '/img/solution/icon05@2x.png',
            },
            {
              name: '灌区信息化物联网监测设备',
              desc: '灌区信息化物联网监测设备以物联感知、视频感知为主。主要包括雨情、水情、工情、墒情、水质、工程安全监测，以及智慧水管理系统所需信息。信息采集的布局应覆盖到信息流全过程，并形成闭环，建成全域覆盖的物联感知网。',
              imgUrl: '/img/solution/icon06@2x.png',
            },
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '雨水情监测',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '农业农田管理',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '水资源管理',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '灌区信息化管理',
            },
          ],
        },
        //城市排水防涝解决方案
        cspsfl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '智慧水务解决方案',
              subTitle: '城市排水防涝',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>为深入贯彻习近平总书记关于防汛救灾工作的重要指示批示精神，落实《国务院办公厅关于加强城市内涝治理的实施意见》(国办发〔2021〕11号)任务要求，进一步加强城市排水防涝体系建设，推动城市内涝治理，加快建立“源头减排、管网排放、蓄排并举、超标应急”的城市排水防涝工程体系。</p>',
            //方案概述
            inner:
                '城市排水防涝系统主要在城市易积水点、易涝点布设雨量计、水位计、电子水尺、流量计、视频监控等监测设备，利用传输设备形成的公网传输链路，把数据汇总到数据中心，由中心进行数据汇聚、整合、分析，建设多个适合管理方的智慧应用，一旦发现异常及时通过多种方式进行预警发布，及时撤离或进行管理调度。最终形成完整的感知-传输-监测-预警-撤离的完整防汛排涝的管理闭环，为城市防汛提供坚实的技术保证。',
            imgUrl: '/img/solution/img_frame06.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '多元化预警发布',
              desc: '发布预警采用广播、LED电子屏、预警灯、短信等多种方式报警。及时避免人员、车辆误入深水路段造成重大人身伤害与经济损失。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '多汇聚智能物联',
              desc: '监控摄像头支持利旧，新接入摄像头支持多品牌多类型接入系统平台。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '智能预警防汛',
              desc: '系统预先录入以往出现内涝的水雨情数据，将实时上报的水雨情信息进行科学化计算处理，在内涝情况未发生前提前预估和预警。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '城市排水防涝平台',
              desc: '城市排水防涝平台针对雨、污水排水全流程进行监控管理，及时预警内涝，科学排水调度，诊断排水管网健康状况，帮助相关管理部门掌握城市排水数据，为评估城市排水能力、消除内涝提供科学依据，提升排水行业现代化管理水平，推动全社会的水安全。',
              imgUrl: '/img/solution/icon06@2x.png',
            },
            {
              name: '城市排水防涝物联网监测设备',
              desc: '运用物联网监测设备，实现降雨、易积水点、排水管网、泵站、河道状态的实时感知、智能控制和精细管理，为评估城市排水能力、消除内涝提供科学依据，为泵站、管网等排水设施改造及维护提供决策支持。',
              imgUrl: '/img/solution/icon05@2x.png',
            },
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '城市排水管理',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水利',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '防汛预警',
              width: 1200
            },
          ],
        },
        //城市污水管网智慧监管
        wsgwzhjg: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '水环境',
              subTitle: '城市污水管网智慧监管',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>根据《关于印发城镇污水处理提质增效三年行动方案（2019—2021年）的通知》及《城镇生活污水处理设施补短板强弱项实施方案》等政策要求，国家将重点加快补齐污水管网等设施短板，为尽快实现污水管网全覆盖、全收集、全处理目标打下基础。</p>',
            //方案概述
            inner:'通过对排水管网液位、流速、流量、水质、浓度等指标进行监测，及时发现并解决雨水混入污水、污水偷排、错排、漏排、管网破损淤堵及城市内涝等问题。实现对管网液位、管网流量、管网有害气体、管网水质等数据采集，实时掌握污水管网运行状况，为污水管网的运行调度、养护管理、快速响应提供有效的数据支持，以便于管理者掌握管网实际状况，能正确部署紧急情况下的应急措施，不断提高污水管网的运行管理水平。',
            imgUrl: '/img/solution/img_frame07.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '管理信息化',
              desc: '在线实时监测液位、流量、水质、堵点等各项指标，实现管网信息化，为管网分析和运行调度提供数据基础。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '预警智能化',
              desc: '系统根据设定的预警阈值自动报警， 便于及时发现管网淤堵、污水溢流等异常现象，快速采取措施避免事故发生，减少人民生命财产损失。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '监管全局化',
              desc: '利用物联网、大数据、云计算微服务技术，实现从排水体系源头、通路、结果进行全方面监控，实现管理精细化、业务流程化，巡查智能化、考核指标化、决策智慧化。',
            }
          ],
          //相关产品
          relatedData: [
            {
              name: '污水管网智慧监管系统',
              desc: '污水官网智慧监管系统利用物联网技术、数据通信技术、地理信息技术、视频处理技术等，建立全面的信息管理、环境保护监测系统，及时发现并解决雨水混入污水、污水偷排、错排、漏排、管网破损淤堵及城市内涝等问题。有利于提升污水处理设施的运维效率和标准化水平，改善人居环境和生态环境。',
              imgUrl: '/img/solution/icon10@2x.png',
            },
            {
              name: '污水官网监测设备',
              desc: '运用流量、水质、液位等监测设备，构建污水监测“一张网“，实现”实时监测、动态分析、预报预警、智能巡查、辅助决策、应急处理”一链路全局化管理，不断提升污水官网技术与运营能力，为区内的水环境治理作出更大的贡献。',
              imgUrl: '/img/solution/icon11@2x.png',
            }
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '市政排水/污水监测',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧水务',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '企业/园区排水监测',
            },
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '智慧城市',
            },
            {
              imgUrl: '/img/product/img17@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧管网',
              width: 1200
            },
          ],
        },
        //智慧环保综合解决方案
        zhhbzhjjfa: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '智慧环保解决方案',
              subTitle: '智慧环保综合',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>生态环境部“十四五”生态环境监测规划中指出：加大生态环境监测科学研究与技术创新力度，加快构建产学研用创新链，推进大数据、人工智能等新技术深度应用，提升网络感知能力、技术实验能力、质量管理能力和智慧分析能力。</p><p>福建省“4+1”管理办法中强调各市、县（区）要在生态云总体框架下，因地制宜深化细化生态云专项应用、建设具有本地特色的业务应用系统，并承担省厅下达的特色应用研发任务，作为生态云的拓展和补充。</p>',
            //方案概述
            inner:
                '利用物联网、AI、大数据等技术，构建县级生态云一张图、形成生态环境数据一本账，结合任务协同平台以及视频AI融合能力平台，实现全面覆盖、智能分析、预警研判、实战应用，助力深入打好污染防治攻坚战。',
            imgUrl: '/img/solution/img_frame08.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '一屏总览',
              desc: '通过数据对接汇聚，实现各系统数据统一展示，一屏知晓全城环境状况。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '一个入口',
              desc: '通过生态云一张图，实现一个账号查看所有数据，免去不同软件切换的繁琐操作，优化管理工作效率。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '数据共享和再利用',
              desc: '通过建立生态云一张图，汇集各级系统数据，便于建立起各类数据关联性，实现一数一源，一源多用，为后期数据深度挖掘和数据智能分析，实现环境污染溯源、环境预警预报等提供支撑。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '智慧环保管理平台',
              desc: '基于福建省生态环境厅生态云系统底座，结合高精度的GIS地图引擎，依托生态环境数据资源中心，整合自建业务系统，实现生态环境数据的集中汇总，统一展示，打造水环境、大气环境、移动执法执法、信访管理、污染源自动监控、污染源视频管控、企业用电监控等多个业务主题一图呈现、一键检索，为决策依据提供准确、直观的参考依据。',
              imgUrl: '/img/solution/icon11@2x.png',
            },
          ],
          // 应用场景
          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '智慧环保',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '智慧生态',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '环境管理',
              width: 1200
            },
          ],
        },
        //农村污水智慧监管解决方案
        ncwszhjg: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '水环境',
              subTitle: '农村生活污水智慧监管',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>根据《关于印发城镇污水处理提质增效三年行动方案（2019—2021年）的通知》及《城镇生活污水处理设施补短板强弱项实施方案》等政策要求，国家将重点加快补齐污水管网等设施短板，为尽快实现污水管网全覆盖、全收集、全处理目标打下基础。</p>',
            //方案概述
            inner:'在农村污水处理站点上加装智能互联设备，实现对站点的运行工况监测、进出水(水量、水质)监测、现场环境监测，增强站点的智能化能力，使之具有远程管理的功能。通过电子工牌、手机定位以及现场视频佐证等多种方式，对站点运维服务人员的到岗情况、到岗时长、运维质量等进行在线监控，确保运维人员按时到岗、足时工作、规范维护。实现所有站点、管线的基础信息、档案、资产等均可在平台地图上快速访问，对站点运行的工况、负荷、能耗、进出水水质、故障可快速进行调度处理，助力农村生活污水处理设施稳定运行。',
            imgUrl: '/img/solution/img_frame09.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '管理信息化',
              desc: '在线实时监测液位、流量、水质、堵点等各项指标，实现管网信息化，为管网分析和运行调度提供数据基础。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '预警智能化',
              desc: '系统根据设定的预警阈值自动报警， 便于及时发现管网淤堵、污水溢流等异常现象，快速采取措施避免事故发生，减少人民生命财产损失。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '监管全局化',
              desc: '利用物联网、大数据、云计算微服务技术，实现从排水体系源头、通路、结果进行全方面监控，实现管理精细化、业务流程化，巡查智能化、考核指标化、决策智慧化。',
            }
          ],
          //相关产品
          relatedData: [
            {
              name: '污水管网智慧监管系统',
              desc: '污水官网智慧监管系统利用物联网技术、数据通信技术、地理信息技术、视频处理技术等，建立全面的信息管理、环境保护监测系统，及时发现并解决雨水混入污水、污水偷排、错排、漏排、管网破损淤堵及城市内涝等问题。有利于提升污水处理设施的运维效率和标准化水平，改善人居环境和生态环境。',
              imgUrl: '/img/solution/icon11@2x.png',
            },
            {
              name: '污水官网监测设备',
              desc: '运用流量、水质、液位等监测设备，构建污水监测“一张网“，实现”实时监测、动态分析、预报预警、智能巡查、辅助决策、应急处理”一链路全局化管理，不断提升污水官网技术与运营能力，为区内的水环境治理作出更大的贡献。',
              imgUrl: '/img/solution/icon12@2x.png',
            }
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '市政排水/污水监测',
            },
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '智慧水务',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '企业/园区排水监测',
            },
            {
              imgUrl: '/img/product/img04@2x.png',
              icon: '/img/product/icon04@2x.png',
              name: '智慧城市',
            },
            {
              imgUrl: '/img/product/img17@2x.png',
              icon: '/img/product/icon05@2x.png',
              name: '智慧管网',
              width: 1200
            },
          ],
        },
        //海漂垃圾治理解决方案
        hpljzl: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '水环境',
              subTitle: '海洋环境综合管理平台',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>2021年，福建省生态环境厅发布关于征求《福建省深入打好污染防治攻坚战的实施意见》和《“十四五”及2021年生态环境有关指标计划》意见明确提出着力打好海漂垃圾综合治理攻坚战。</p><p>在省委、省政府的统一部署下，我市积极开展海漂垃圾综合治理工作，2021年5月，我市印发《漳州市进一步加强海漂垃圾综合治理工作方案》，计划在2022年，进一步健全海漂垃圾治理长效机制，完善常态化、网格化、动态化的海漂垃圾综合治理模式。</p>',
            //方案概述
            inner:'以改善区域海洋生态环境质量为核心，综合运用卫星遥感、无人机、视频监控、海上自动浮标站等空天地海智能感知手段，实现重点直排海污染源污水和尾水排放监测、入海排污口分类整治、海漂垃圾智慧监管、“美丽海湾”精细化单元管控和全天候可视化监管，全面支撑重点海域污染防治攻坚战，强化陆域海域污染协同治理，打好海漂垃圾综合治理攻坚战，推进“美丽海湾”保护与建设。',
            imgUrl: '/img/solution/img_frame10.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon013.png',
              name: '卫星遥感智能识别',
              desc: '运用卫星遥感、无人机等手段，快速地从大尺度上发现海漂垃圾的分布，精准地发现海岸带海漂垃圾的分布。',
            },
            {
              icon: '/img/solution/icon014.png',
              name: '重点海湾布设视频',
              desc: '针对海漂垃圾重点分布的岸滩、河流入海口、近海海域等重点海湾，部署视频监控探头，小尺度持续监管、解决问题。',
            },
            {
              icon: '/img/solution/icon015.png',
              name: 'AI提取有效数据',
              desc: '通过人工智能算法，将原来无用的视频数据，结构化处理，形成一个数据报告，精准调度环卫队伍对垃圾及时处理。',
            },
            {
              icon: '/img/solution/icon016.png',
              name: '分级监管自动检验',
              desc: '环境管理部门既可通过手机APP督办辖区范围内的垃圾清扫任务，也可查阅每次清扫结束后的现场画面前后对比图。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '漂垃圾综合治理平台',
              desc: '通过遥感影像、无人机、摄像机等前端设备采集图片或视频信息，结合人工智能算法模型识别多种类型海漂，通过对大坝表面变形、渗流、渗压等异常情况进行自动监测，通过建立水位和位移、渗流量、浸润线的对应关系，结合人工智能算法模型分析大坝安全情况，对大坝的安全情况自动生成报告及评级管理，助力水库大坝安全智能监管。',
              imgUrl: '/img/solution/icon11@2x.png',
            },
            {
              name: '智算精灵',
              desc: '边缘智算系列产品是针对泛自然资源领域定制的数据、算法和算力“三融合”的视频智能分析终端，产品可集成我司自主研发的高精度深度学习算法。',
              imgUrl: '/img/solution/icon12@2x.png',
            }
          ],
          sceneData: [
            {
              imgUrl: '/img/product/img14@2x.png',
              icon: '/img/product/icon14@2x.png',
              name: '海洋生态管理',
            },
            {
              imgUrl: '/img/product/img15@2x.png',
              icon: '/img/product/icon15@2x.png',
              name: '海岸线环境管理',
            },
            {
              imgUrl: '/img/product/img16@2x.png',
              icon: '/img/product/icon16@2x.png',
              name: '河湖环境管理',
            },
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '水库环境管理',
            },
          ],
        },
        //固危废全流程AI监管解决方案
        gwfqlc: {
          swiperList: [
            {
              imgUrl: '/img/solution/img_top02.png',
              title: '企业环保',
              subTitle: '固体废物和危险化学品管理',
              btn: 'contact',
            },
          ],
          summaryData: {
            //政策背景
            desc: '<p>福建省生态环境厅印发的《福建省固体废物环境信息化应用管理规定》指出，生态环境部门不定期对重点环境监管单位在关键点位或重要点位安装的视频，采用视频连线方式，远程查阅视频监控记录，视频相关资料，开展远程规范化督导帮扶，发挥环境信息化监管实效。</p><p>深入结合应用人工智能技术，借助视频AI分析算法，实现重点产废、经营、处置单位的出入库行为、车辆、人员等识别预警，利用智能设备实时采集相关数据，有效提升固废（危废）规范化管理水平。</p>',
            //方案概述
            inner:'通过对危险废物经营单位和重点危险废物产生单位布设视频监控和危险废物智能化设备，加强对危险废物的全过程监管，摸清危险废物产生、贮存、转移、利用处置情况，通过智能算法及时发现异常，并进行预警、视频取证，以实现“主动发现、主动取证、主动核查、主动执法”的全过程监管闭环，实现危险废物智能化、实时化、集成化监控，全面提升危险废物过程信息化监管水平，有效防范环境风险隐患。',
            imgUrl: '/img/solution/img_frame11.png',
          },
          //核心优势
          advantageData: [
            {
              icon: '/img/solution/icon001.png',
              name: '智能AI算法',
              desc: '自动抓取出入库、过磅、进出料口、车辆进出等视频关键信息，关联固废系统联单记录，及时发现异常，并进行预警、视频取证。',
            },
            {
              icon: '/img/solution/icon002.png',
              name: '智能物联设备',
              desc: '采用智能电子秤设备，实现对固废精准称重，自动上传重量、类别等数据，避免出现人为的误报、漏报、错报。',
            },
            {
              icon: '/img/solution/icon003.png',
              name: '一码智能溯源',
              desc: '通过打印固危废二维码标签，结合视频监控实现从称重、标签、出入库、申报到转移等全流程记录。',
            },
            {
              icon: '/img/solution/icon004.png',
              name: '多屏智能应用',
              desc: '手机、电脑、智能终端等交叉应用，实现固危废从产废到处置的快速、高效监管，异常事件及时预警和处置。',
            },
          ],
          //相关产品
          relatedData: [
            {
              name: '固危废全流程AI监管平台',
              desc: '通过建立固危废全流程监管体系实现对固废危废产生、收集、贮存、运输（转移）、处置全生命周期管理。利用物联网、大数据智能分析等技术，实现危险废物生产，收集，存储，转移，利用，处置等重点环节，重要场所智能监控规范化，标准化，集约化建设，实现互联互通，智能转化，完善智能化监控系统，切实提供固危废环境管理水平和效率。',
              imgUrl: '/img/solution/icon09@2x.png',
            },
            {
              name: '固危废全流程AI监管硬件设备',
              desc: '充分利用智能视频、智能电子秤、GPS、二维码等物联网技术，实时采集、分析、整合产废、贮存、转移和处置过程中各类的信息，通过智能算法及时发现异常，并进行预警、视频取证，以实现主动发现、主动取证、主动核查、主动执法的全过程监管闭环。',
              imgUrl: '/img/solution/icon10@2x.png',
            }
          ],

          sceneData: [
            {
              imgUrl: '/img/product/img01@2x.png',
              icon: '/img/product/icon01@2x.png',
              name: '企业固危废管理',
            },
            {
              imgUrl: '/img/product/img02@2x.png',
              icon: '/img/product/icon02@2x.png',
              name: '医院危废管理',
            },
            {
              imgUrl: '/img/product/img03.png',
              icon: '/img/product/icon03@2x.png',
              name: '生态环保',
              width: 1200
            }
          ],
        },
      },
    }
  },
  created() {
    console.log('created')
    let solutionsId = this.$route.params.id
    this.selectSolutions = this.solutionsData[solutionsId]
  },
  mounted() {
    console.log('mounted')
    let solutionsId = this.$route.params.id
    this.selectSolutions = this.solutionsData[solutionsId]
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/util.scss";
@import "src/assets/css/style.scss";
.solutions-banner {
  width: 100%;
  height: 160px;
  background: url("/img/solution/img_top01.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .solutions-banner-title {
    position: absolute;
    top: 52px;
    left: 32px;
    font-size: 14px;
    text-align: left;
    color: #929caa;

    line-height: 20px;
  }
  .solutions-banner-sub-title {
    position: absolute;
    top: 80px;
    left: 32px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    line-height: 28px;
  }
}
.solutions-desc {
  width: 100%;
  background: #fafafb;
  padding: 32px 20px;
  box-sizing: border-box;
  .solutions-desc-card {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.03);
    text-indent: 2em;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    color: $primary-text;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
}
.advantage-box {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  justify-content: flex-start;
  margin: 16px -6px -6px -6px;
  .advantage-item {
    padding: 16px 12px 24px 12px;
    box-sizing: border-box;
    margin: 0 6px;
    margin-top: 12px;
    width: calc(50% - 12px);
    background-image: linear-gradient(0deg, #ffffff, #f3f5f8);
    border: 2px solid #fff;
    //background: url("/img/solution/card.png") no-repeat;
    //background-size: 100% 100%;
    //height: 170px;
    border-radius: 4px;
    box-shadow: 0px 4px 25px 0px rgba(216,222,228,0.50);
    .advantage-item-img {
      width: 32px;
      height: 32px;
      margin-left: calc(50% - 16px);
    }
    .advantage-item-name {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      color: $primary-text;
      line-height: 22px;
      letter-spacing: 0.02px;
    }
    .advantage-item-desc {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      letter-spacing: 0.02px;
    }
  }
}
</style>

<template>
  <div class="h5-box">
<!--    介绍 -->
    <div
        id="jieshao"
        class="top-banner"
        :class="system === 'wanfu' && 'top-banner_wanfu' || system === 'nanwei' && 'top-banner_wanfu' || 'top-banner_wanfu'"
    >
      <button class="mobile-btn top-banner-btn" @click="goToContactWe">产品咨询</button>
    </div>
<!--    关于万福-->
    <ModuleWrap
      id="aboutWanfu"
      class="about-wanfu-box"
      :title="aboutUsData.title"
    >
      <div class="about-wanfu-box-desc">{{ aboutUsData.desc }}</div>
      <div class="aboutwf-video" @click="play">
        <div class="play-icon" v-show="!playing" @click.stop="play"></div>
        <video
            width="100%"
            :controls="playing"
            height="100%"
            ref="video"
            poster="/img/home/videoImg.png"
            webkit-playsinline='true'
            playsinline='true'
        >
          <source :src="aboutUsData.videoUrl" type="video/mp4" />您的浏览器不支持Video标签。
        </video>
      </div>
    </ModuleWrap>
<!--    聚焦4大场景，围绕视觉AI，构建3大平台-->
    <ModuleWrap
        title="聚焦4大场景，围绕视觉AI，构建3大平台"
        bg-img="/img/home/bg03.png"
        sub-title="赋能环保水利 引领数智创新"
    >
      <div class="solution-box">
        <div
            :ref="`roll${index}`"
            class="solution-box-item roll"
            :style="{
              margin: item === selectSolution ? '12px 0' : 0,
              borderBottom: solutionData.indexOf(selectSolution) === index + 1 ? '1px solid #d2d9dd' : '0'
            }"
            v-for="(item,index) in solutionData"
            :key="item.name"
        >
          <div class="solution-box-item-text" :class="item === selectSolution && 'solution-box-item-text-active'" @click="onClickSolution(item,index)">
            <div>{{item.name}}</div>
            <img class="solution-box-item-text-img" :src="item === selectSolution ? '/img/home/icon_arrow_up.png' : '/img/home/icon_arrow_down.png'" alt="">
          </div>
          <div class="solution-box-item-children" v-show="item === selectSolution">
            <div
              class="solution-box-item-children-box"
              v-for="items in item.children"
              :key="items.name"
              @click="goToSolution(items)"
            >
              <div class="solution-box-item-children-title">{{items.name}}</div>
              <div class="solution-box-item-children-content">{{items.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </ModuleWrap>
<!--    核心能力-->
    <ModuleWrap
        class="products-box"
        :title="productsData.title"
        tit-col="#fff"
        :sub-title="productsData.subTitle"
        bg-img="/img/home/bg02.png"
    >
      <div class="products-box-content">
        <div
            class="products-box-content-item"
            v-for="(item,index) in productsData.children"
            :key="index"
            @click="goToProducts(item,index)"
        >
          <div class="round"></div>
          {{item.name}}
        </div>
      </div>
    </ModuleWrap>
<!--    硬件产品-->
    <ModuleWrap
        :title="hardwareData.title"
        :sub-title="hardwareData.subTitle"
        bg-img="/img/home/bg05.png"
    >
      <div class="hardware-box">
        <swiper style="height: 100%" :options="imgOption" class="img-swiper" ref="imgSwiper">
          <swiper-slide
            style="height: 95%;"
            v-for="(children,ind) of hardwareData.children"
            :key="ind"
          >
            <div
              class="hardware-box-card"
              v-for="(item) in children.array"
              :key="item.name"
              @click="goTohardwareDetail(item)"
            >
              <div class="hardware-box-card-name">{{item.name}}</div>
              <div class="hardware-box-card-img" :style="{backgroundImage: `url(${item.img})`}"></div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </ModuleWrap>
<!--    近期精彩案例-->
    <ModuleWrap
        class="case-box"
        title="近期精彩案例"
    >
      <WanfuH5Tabs
          style="margin-left: -20px;width: calc(100% + 40px);margin-top: 16px"
          :data="caseData"
          @onClick="onClickCaseData"
      />
      <div
        v-for="(item,index) in selectCase.children"
        class="case-box-card"
        :class="`case-box-card-${index}`"
        :key="item.name"
        @click="goToCaseDetail(item)"
      >
        <div class="case-box-card-title">{{item.name}}</div>
        <div class="case-box-card-content">{{item.content}}</div>
      </div>
    </ModuleWrap>
<!--    统计（安全可信）-->
    <ModuleWrap
        class="analysis-box"
        :title="analysisData.title"
        tit-col="#fff"
        :sub-title="analysisData.subTitle"
        bg-img="/img/home/bg04.png"
    >
      <div style="display: flex;">
        <div
            class="analysis-box-content"
            v-for="item in analysisData.data"
            :key="item.label"
        >
          <div class="analysis-box-content-num">{{item.num}}{{item.unit}}</div>
          <div class="analysis-box-content-label">{{item.label}}</div>
        </div>
      </div>
    </ModuleWrap>
<!--    产品咨询-->
    <Consultation />
  </div>
</template>

<script>
import {system} from "@/config/system_type";
import ModuleWrap from '../components/moduleWrap/index';
import WanfuH5Tabs from '../components/wanfuH5Tabs/index';
import Consultation from './consultation';
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: "home",
  components: {
    Consultation,
    ModuleWrap,
    WanfuH5Tabs,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      imgOption: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        initialSlide: 0, //默认第几张
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        loop: false, //循环
        simulateTouch: true,
        autoplayDisableOnInteraction: false, //触摸后再次自动轮播
        autoplay: 2000, //每张播放时长3秒，自动播放
        speed: 1000, //滑动速度
      },
      system,
      playing: false,
      aboutUsData: {
        title: '',
        desc: '',
        videoUrl: '',
      },
      // ----------------- 聚焦4大场景 -----------------
      selectSolution: null,
      solutionData: [
        {
          name: '水环境',
          children: [
            {
              name: '水环境综合管理平台',
              content: '以改善区域水生态环境质量为核心，以全面支撑水环境质量持续改善目标。充分运用物联网、视觉AI、数据智能等智能技术手段，集成整合区域水环境数据资源，围绕重点断面水质考核、功能区水质达标、跨界断面考核、流域生态补偿、水污染溯源分析、水环境整治等核心主题形成大数据分析能力，全面支撑水环境质量持续改善管理目标的实现。',
              path: '/solutions/szyzhgl',
            },
            {
              name: '河湖“四乱”综合整治',
              content: '利用视觉AI技术有效提取河道、湖泊等水环境要素特征，智能识别“乱建”、“乱占”、“乱堆”、“乱采”四乱问题并联动取证，实现无人值守全天候监控预警。具备水域岸线违章检测、河道漂浮物检测、倾倒垃圾监测、非法采砂船识别等多种涉水对象检测-识别-分类能力，构建以视图为核心的“发现问题-问题取证-派发处置-治理成效分析”全链式在线管理体系，服务河湖长制。',
              path: '/solutions/hhslzl',
            },
            {
              name: '城市污水管网智慧监管',
              content: '通过对排水管网液位、流速、流量、水质、浓度等指标进行监测，及时发现并解决雨水混入污水、污水偷排、错排、漏排、管网破损淤堵及城市内涝等问题。实现对管网液位、管网流量、管网有害气体、管网水质等数据采集，实时掌握污水管网运行状况，为污水管网的运行调度、养护管理、快速响应提供有效的数据支持，以便于管理者掌握管网实际状况，能正确部署紧急情况下的应急措施，不断提高污水管网的运行管理水平。',
              path: '/solutions/wsgwzhjg',
            },
            {
              name: '农村生活污水智慧监管',
              content: '在农村污水处理站点上加装智能互联设备，实现对站点的运行工况监测、进出水(水量、水质)监测、现场环境监测，增强站点的智能化能力，使之具有远程管理的功能。通过电子工牌、手机定位以及现场视频佐证等多种方式，对站点运维服务人员的到岗情况、到岗时长、运维质量等进行在线监控，确保运维人员按时到岗、足时工作、规范维护。实现所有站点、管线的基础信息、档案、资产等均可在平台地图上快速访问，对站点运行的工况、负荷、能耗、进出水水质、故障可快速进行调度处理，助力农村生活污水处理设施稳定运行。',
              path: '/solutions/ncwszhjg',
            },
            {
              name: '海洋环境综合管理平台',
              content: '以改善区域海洋生态环境质量为核心，综合运用卫星遥感、无人机、视频监控、海上自动浮标站等空天地海智能感知手段，实现重点直排海污染源污水和尾水排放监测、入海排污口分类整治、海漂垃圾智慧监管、“美丽海湾”精细化单元管控和全天候可视化监管，全面支撑重点海域污染防治攻坚战，强化陆域海域污染协同治理，打好海漂垃圾综合治理攻坚战，推进“美丽海湾”保护与建设。',
              path: '/solutions/hpljzl',
            },
          ],
        },
        {
          name: '水工程',
          children: [
            {
              name: '智慧水库管理系统',
              content: '智慧水库管理系统以“天天安全、百分之百安全”的目标要求，紧紧围绕水库安全管理的各项工作，利用物联网、云计算、大数据、人工智能、三维仿真、水利专业模型等跨学科现代先进技术，搭建区域水库综合管理平台，为水库管理的“三个责任人”提供档案数据归整、巡查自动记录、水工情智能监测、数据智能模拟、报告自动生成和问题闭环管理等服务。为落实“三个重点环节”提供数据支撑和应用服务，为水库的安全运行提供信息化管理手段。对水库水位、雨量、生态下泄流量进行远程监测和监管，确保水库洪涝灾害的预防和下游生态环境的和谐发展。',
              path: '/solutions/zhskgl',
            },
            {
              name: '灌区信息化系统',
              content: '灌区信息化系统通过在总干渠及干渠补充建设量测水监测设施、视频监视设备以及闸门控制设备，建立健全完善的通信传输体系以及供电体系，实现总干渠及干渠的信息采集自动化、通信传输网络化；通过在水利局建设灌区数据中心、应用支撑系统，实现灌区数据管理规范化、预警预报模型化；开发灌区管控一张图、闸门监控系统、视频监视系统、水量调度管理系统、综合信息监测管理系统、水量计量与水费计收管理系统、工程运维管理系统以及综合办公管理系统，实现灌区决策分析智能化。灌区信息化系统充分发挥灌溉效益，提高灌溉水利用系数，为灌区水资源的优化配置、高效利用、调度运行提供决策支持，同时变被动服务为主动服务，吸引公众参与。',
              path: '/solutions/gqxxhgl',
            },
          ],
        },
        {
          name: '水资源',
          children: [
            // {
            //   name: '水资源综合调度管理平台',
            //   content:
            //     '待定',
            //   path: '/solutions/szyzhgl',
            // },
            {
              name: '城乡供水一体化',
              content: '利用云计算、大数据、物联网技术构建城乡供水一体化解决方案，实现厂站供水、排水、用水用户、水管监测设备数据融合，全面提升城市供水安全、生产管理、监控调度、管网管理、表务管理、决策分析能力，保障城乡供水安全、节水效率和服务质量。',
              path: '/solutions/cxgsyth',
            }
          ],
        },
        {
          name: '企业环保',
          children: [
            {
              name: '固体废物和危险化学品管理',
              content: '通过对危险废物经营单位和重点危险废物产生单位布设视频监控和危险废物智能化设备，加强对危险废物的全过程监管，摸清危险废物产生、贮存、转移、利用处置情况，通过智能算法及时发现异常，并进行预警、视频取证，以实现“主动发现、主动取证、主动核查、主动执法”的全过程监管闭环，实现危险废物智能化、实时化、集成化监控，全面提升危险废物过程信息化监管水平，有效防范环境风险隐患。',
              path: '/solutions/gwfqlc',
            }
          ],
        },
      ],
      // ----------------- 核心能力 -----------------
      productsData: {
        title: '核心能力',
        subTitle: '赋能环保水利 引领数智创新',
        children: [
          {
            name: 'VIMS视频能力服务平台',
            code: 'vims',
            path: '/products/spzngl',
            status: 'HOT',
          },
          {
            name: 'UMIoT元物联平台',
            code: 'umiot',
            path: '/products/ywl',
            status: ''
          },
          {
            name: 'EAIGo视频智能分析系统',
            code: 'eaigo',
            path: '/products/spznfx',
            status: ''
          },
        ]
      },
      // ----------------- 硬件产品 -----------------
      hardwareData: {
        title: '硬件产品',
        subTitle: '赋能环保水利 引领数智创新',
        children: [
          {
            array:[
              {
                name: 'EAI智算精灵',
                code: 'eaigo',
                path: '/hardware/eaigo',
                status: '',
                img: '/img/home/img_box01@2x.png'
              },
              {
                name: '水利一杆通WF-JC100',
                code: 'slygt',
                path: '/hardware/slygt',
                status: '',
                img: '/img/home/img_box02@2x.png'
              },
              {
                name: '存算一体VGIS-N7100',
                code: 'csyt',
                path: '/hardware/csyt',
                status: '',
                img: '/img/home/img_box03@2x.png'
              },
              {
                name: '遥测终端机',
                code: 'rtu',
                path: '/hardware/rtu',
                status: '',
                img: '/img/home/img_box04@2x.png'
              },
              {
                name: '多通道振弦采集仪',
                code: 'dtdzxcjy',
                path: '/hardware/dtdzxcjy',
                status: '',
                img: '/img/home/img_box05@2x.png'
              },
            ]
          },
          {
            array:[
              {
                name: '雨量计',
                code: 'hyetometer',
                path: '/hardware/hyetometer',
                status: '',
                img: '/img/home/img_box06@2x.png'
              },
              {
                name: '气泡式水位计',
                code: 'qpsswj',
                path: '/hardware/qpsswj',
                status: '',
                img: '/img/home/img_box07@2x.png'
              },
              {
                name: '雷达水位计',
                code: 'ldswj',
                path: '/hardware/ldswj',
                status: '',
                img: '/img/home/img_box08@2x.png'
              },
              {
                name: '振弦式渗压计',
                code: 'zxssyj',
                path: '/hardware/zxssyj',
                status: '',
                img: '/img/home/img_box09@2x.png'
              },
              {
                name: '磁致式量水堰计',
                code: 'czslsyj',
                path: '/hardware/czslsyj',
                status: '',
                img: '/img/home/img_box10@2x.png'
              },
            ]
          },
          {
            array:[
              {
                name: '流量计',
                code: 'flowmeter',
                path: '/hardware/flowmeter',
                status: '',
                img: '/img/home/img_box12@2x.png'
              },
              {
                name: '表面变形监测',
                code: 'bmbxjc',
                path: '/hardware/bmbxjc',
                status: '',
                img: '/img/home/img_box13@2x.png'
              },
            ]
          }
        ]
      },
      // ----------------- 近期精彩案例 -----------------
      selectCase: {
        value: '1',
        label: '水环境',
        name: '水环境',
        children: [
          {
            name: '罗源南门溪、起步溪水环境综合整治项目',
            content: '罗源南溪、起步溪水环境综合整治项目以改善罗源县水环境质量为核心，按照“治理整体化、截污最大化、两岸景观化、沿岸生态化、管理制度化”的建设精神，确保本区域资源开发与防洪、水利、环境功能综合协调。开展流域内主要断面水质监测分析和预警工作，对流域沿线工业企业、农业面源、水产养殖、畜禽养殖、牛蛙养殖污染状况、农村生活污水治理情况等各类排口精准摸排，形成问题清单；通过“数字治水”有效监督和解决“乱占”、“乱采”、“乱堆”、“乱建”的“四乱”问题，最终实现各级各部门信息共享，将每个环节的责任落实到具体单位和具体责任人，实现综合执法工作联动。项目用新技术、新机制落实河长制，最大限度地发挥其环境效益、社会效益及经济效益，实现水长清、岸长绿、河长治。',
            imgUrl: '/img/bg_card02.png',
            path: '/caseDetail/lynmx',
          },
          {
            name: '海南省定安县城乡污水治理项目',
            content:
                '本项目涉及定安县黄竹、龙湖、龙门、翰林等4个建制镇及其下辖的35个行政村(共计343个自然村)，覆盖面积5260K㎡，建成运行后将服务当地115695人，可削减污染物排放量:污水量1663m3/d，CODcr约260吨/年，氨氨约20吨/年。对实现定安县黄竹、龙湖、龙门、翰林4个地区的乡村振兴有着深远意义。',
            imgUrl: '/img/bg_card03.png',
            path: '/caseDetail/hnsdaxwszl',
          },
          {
            name: '仙游县农村生活污水提升治理智慧监管项目',
            content: '仙游县农村生活污水提升治理智慧监管项目根据农村生活污水设施处理规模和所在区域环境敏感程度等因素，采用在线监测和手工监测相结合、水质监测和工况监控相结合、环境监管及属地巡检相结合的方式，充分应用物联网和信息化等非现场监管手段，开展农村生活污水处理设施分类监测监控工作，督促设施稳定运行、尾水达标排放。通过安装县域农村生活污水监管系统，对接省生态云平台绿盈乡村板块，汇聚设施分类监测监控、问题转办督办等涉及农村生活污水提升治理任务的相关数据，统一建档落图，实现非现场巡查、自动化预警、在线调度管理等功能，全面提升仙游县农村生活污水治理智慧监管水平。',
            imgUrl: '/img/bg_card01.png',
            path: '/caseDetail/xyncshws',
          },
        ]
      },
      caseData: [
        {
          value: '1',
          label: '水环境',
          name: '水环境',
          children: [
            {
              name: '罗源南门溪、起步溪水环境综合整治项目',
              content: '罗源南溪、起步溪水环境综合整治项目以改善罗源县水环境质量为核心，按照“治理整体化、截污最大化、两岸景观化、沿岸生态化、管理制度化”的建设精神，确保本区域资源开发与防洪、水利、环境功能综合协调。开展流域内主要断面水质监测分析和预警工作，对流域沿线工业企业、农业面源、水产养殖、畜禽养殖、牛蛙养殖污染状况、农村生活污水治理情况等各类排口精准摸排，形成问题清单；通过“数字治水”有效监督和解决“乱占”、“乱采”、“乱堆”、“乱建”的“四乱”问题，最终实现各级各部门信息共享，将每个环节的责任落实到具体单位和具体责任人，实现综合执法工作联动。项目用新技术、新机制落实河长制，最大限度地发挥其环境效益、社会效益及经济效益，实现水长清、岸长绿、河长治。',
              imgUrl: '/img/bg_card02.png',
              path: '/caseDetail/lynmx',
            },
            {
              name: '海南省定安县城乡污水治理项目',
              content:
                  '本项目涉及定安县黄竹、龙湖、龙门、翰林等4个建制镇及其下辖的35个行政村(共计343个自然村)，覆盖面积5260K㎡，建成运行后将服务当地115695人，可削减污染物排放量:污水量1663m3/d，CODcr约260吨/年，氨氨约20吨/年。对实现定安县黄竹、龙湖、龙门、翰林4个地区的乡村振兴有着深远意义。',
              imgUrl: '/img/bg_card03.png',
              path: '/caseDetail/hnsdaxwszl',
            },
            {
              name: '仙游县农村生活污水提升治理智慧监管项目',
              content: '仙游县农村生活污水提升治理智慧监管项目根据农村生活污水设施处理规模和所在区域环境敏感程度等因素，采用在线监测和手工监测相结合、水质监测和工况监控相结合、环境监管及属地巡检相结合的方式，充分应用物联网和信息化等非现场监管手段，开展农村生活污水处理设施分类监测监控工作，督促设施稳定运行、尾水达标排放。通过安装县域农村生活污水监管系统，对接省生态云平台绿盈乡村板块，汇聚设施分类监测监控、问题转办督办等涉及农村生活污水提升治理任务的相关数据，统一建档落图，实现非现场巡查、自动化预警、在线调度管理等功能，全面提升仙游县农村生活污水治理智慧监管水平。',
              imgUrl: '/img/bg_card01.png',
              path: '/caseDetail/xyncshws',
            },
          ],
        },
        {
          value: '2',
          label: '水工程',
          name: '水工程',
          children: [
            {
              name: '德化县小型水库雨水情测报和大坝安全监测项目',
              content: '德化县位于福建省中部、泉州市北部，全县小（二）型以上水库59座，总库容3.17亿立方米。项目以“向科技要人力、以创新激活力”为原则，持续探索科学的管理体制和模式，对县内湖库进行统一规划，安装智慧水库系统，实现湖库管理信息化、智能化，推动湖库高质量管护，更好地保障湖库安全；运用人工智能技术对水库的违章建筑、人员垂钓、下水游泳等行为进行自动识别和联动告警，联动广播音柱实现自动喊话，有效防治上述行为，大大提升了湖库的管护效率；通过系统连续监测，水库雨水情、大坝安全等特征要素实现自动测报，管理人员使用电脑或手机端即可实时掌握水库运行情况，为水库的日常治理、护航自然灾害和极端天气下的水库安全管理发挥重要作用。2022年11月，德化县“科技赋能，保一方湖库平安”的先进案例也得到了央视网关注和报道。',
              imgUrl: '/img/bg_card01.png',
              path: '/caseDetail/dhsk',
            },
            {
              name: '漳浦县水库雨水情和安全设施监测系统',
              content: '本项目建设以“统一规划、上下连接，讲求效益、注重应用”为原则，以水库雨水情和大坝安全监测物联网+视频AI算法智能分析管控为目标，基于现有设施资源的基础上，按照水利设施感知信息完善化、精细化、智能化的管理思路，让监管人员对水库的雨水情和安全监测监管，实现远程化监管应用，可视化决策分析。',
              imgUrl: '/img/bg_card02.png',
              path: '/caseDetail/zpxsk',
            },
          ],
        },
        {
          value: '3',
          label: '水资源',
          name: '水资源',
          children: [
            {
              name: '泉州市水资源综合调度平台-智慧水利一期',
              content: '泉州市水资源综合调度平台-智慧水利一期充分运用云计算、物联网、大数据、移动互联网、人工智能等新一代信息技术，建设泉州市智慧水利平台。平台通过强化水资源调度与小流域山洪灾害预报预警模型算法，实现泉州水资源“多源互补、丰枯调剂、环境优美”的科学联合调度目标。泉州市水资源综合调度平台累计汇聚了1500多个水利设施基础信息，包括水库、水闸、提防、泵站、引水工程、水电站等，同时接入了900多个气象、水文、预警、山洪的实时监测站点，累积采集了4000万多的实时监测数据，为泉州市智慧水利的建设奠定坚实的基础，有效助力了泉州“上蓄下引、河库连通”的水网工程体系建设。',
              imgUrl: '/img/bg_card01.png',
              path: '/caseDetail/qzzhsl',
            },
          ],
        },
        {
          value: '4',
          label: '企业环保',
          name: '企业环保',
          children: [
            {
              name: '福建省危险废物智能化系统项目',
              content: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              imgUrl: '/img/bg_card03.png',
              path: '/caseDetail/fjswxfw',
            },
          ],
        },
        {
          value: '5',
          label: '核心能力',
          name: '核心能力',
          children: [
            {
              name: '福建省“生态云“视频能力平台',
              content: '福建省生态云平台是针对生态环境部门管理人员用户，通过环保专网登入的监管应用平台，是一个大平台、大数据、大系统的云平台，是形成分析决策指挥一张图的平台。生态云视频能力平台累计接入全省1万多路生态环境相关视频资源，包括涉疫医疗单位、涉疫医疗废物处置单位、饮用水源地、固定污染源、国控断面、大气监测、废铅蓄电池收集企业、污染地块监测、固废-危废-经营单位、海漂垃圾监测、核辐射等 10 多个环保专题的 100多种监控部位。平台曾多次获得中央深改委、国务院办公厅、生态环境部等刊文、表扬和转发，由视频能力平台提供核心能力的《创新建立海漂垃圾综合治理长效机制 坚决守护碧海银滩》荣获福建省第四届“推进机制活、建设新福建”全省机关体制机制创新优秀案例“一等奖”。',
              imgUrl: '/img/bg_card03.png',
              path: '/caseDetail/fjswxfw',
            },
            {
              name: '水利视频AI示范应用',
              content: '利用视频AI技术有效提取河道、湖泊、水库等水环境要素特征，识别违规行为并取证，实现无人值守全天候监控预警。研发了水葫芦识别、河道漂浮物检测、水域岸线违章检测、非法采砂船识别等10多种涉水对象检测-识别-分类能力，构建以视图为核心的水利全链式在线管理体系。',
              imgUrl: '/img/bg_card01.png',
              path: '/caseDetail/slspaisf',
            },
            {
              name: '莆田市“全域数字化全市一张图”视频服务平台',
              content: '莆田市“全域数字化，全市一张图”统筹运用数字化技术、思维、能力，搭建大数据调度指挥平台，把数字化贯彻到莆田经济、政治、文化、社会、生态文明建设全过程各方面，对市域治理的模式进行全方位、系统性重塑，做到精准、科学、依法、高效，全面提升社会治理体系和治理能力现代。相关工作纳入了《2022 年数字福建工作要点》。其中，视频服务平台首创了城市级、多租户视频资源标签治理体系，与莆田市政务 0A 系统打通，实现申请、审批、调阅、共享全程留痕，助力政府视频安全、有序地共享。累计接入全市各部门 15多万路视频，实现跨部门视频共享共9万多路，累计开展视频标签治理 20多万次，形成标签数据3万多条，支撑了防汛防台风、木兰溪综合治理、防疫物资“一张图”、“党建引领、夯基惠民”、五联防“一张图”等专题应用。',
              imgUrl: '/img/bg_card02.png',
              path: '/caseDetail/ptsyzt',
            },
          ],
        },
      ],
      // ----------------- 安全可信 快捷部署 -----------------
      analysisData: {
        title: '安全可信 快捷部署',
        subTitle: '500+客户选择与万福同行',
        data: [
          { num: 500, label: '合作客户', unit: '+' },
          { num: 20, label: '行业覆盖', unit: '+' },
          { num: 1000, label: '应用场景', unit: '+' },
          { num: 200, label: '样本数量', unit: '万+' },
        ]
      },
    }
  },
  created() {
    if (system === 'wanfu') {
      this.aboutUsData = {
        title: '关于万福',
        desc: '福建万福信息技术有限公司（以下简称“万福信息”）成立于2017年，公司总部位于福州市高新区万福中心。万福信息致力于环保、水利等行业的数字化转型和提升，2021年1月获南威软件（股票代码：603636）战略投资。万福信息以AI行业应用为锚点，以环保、水利业务场景为驱动，力求贴近、再贴近客户需求，构建AI体系化、场景化能力，实现技术和业务的有机融合。',
        videoUrl: '/img/home/wfvideo.mp4',
      };
    } else if (system === 'nanwei') {
      this.aboutUsData = {
        title: '关于南威数字生态介绍',
        desc: '南威数字生态聚焦于环保、水利等行业的数字化转型和提升，以AI行业应用为锚点，以环保、水利业务场景为驱动，力求贴近、再贴近客户需求，构建AI体系化、场景化能力，实现技术和业务的有机融合。南威软件积极投身于生态环境信息化，战略投资万福信息，共创数字生态未来。',
        videoUrl: '/img/home/wfvideo.mp4',
      };
    }
  },
  mounted() {
    this.selectSolution = this.solutionData[0]
    console.log(this.$refs['router-box'])
  },
  methods: {
    /** 跳转产品咨询 **/
    goToContactWe() {
      this.$router.push('/contactWe')
    },
    /** 播放视频 **/
    play() {
      if (this.playing) {
        this.playing = false
        this.$refs.video.pause()
      } else {
        this.playing = true
        this.$refs.video.play()
      }
    },
    /** 选择（聚焦4大场景） **/
    onClickSolution(item) {
      if (item === this.selectSolution) {
        this.selectSolution = null
      } else {
        this.selectSolution = item
      }
      setTimeout(()=>{
        let pageId = document.querySelector("#router-box");
        let aboutWanfu = document.querySelector("#aboutWanfu");
        let jieshao = document.querySelector("#jieshao");
        pageId.scrollTo({
          top: aboutWanfu.clientHeight + jieshao.clientHeight, //y 上线
          left:0,//x 左右
          behavior:'smooth',//属性值：默认值auto（相当于instant）、平滑滚动smooth、瞬间滚动instant
        });
      },10)
    },
    /** 跳转解决方案 **/
    goToSolution(items) {
      this.$router.push(items.path)
    },
    /** 跳转核心能力 **/
    goToProducts(item) {
      console.log(item)
      this.$router.push(item.path)
    },
    /** 跳转硬件产品 **/
    goTohardwareDetail(item) {
      this.$router.push(item.path)
    },
    /** tabs **/
    onClickCaseData(item) {
      console.log(item)
      this.selectCase = item
    },
    /** 跳转项目案例 **/
    goToCaseDetail(item) {
      this.$router.push(item.path)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/util.scss";
@import "src/assets/css/style.scss";
.top-banner_wanfu {
  background: url("/img/home/banner_wanfu.png") no-repeat;
  background-size: 100% 100%;
}
.top-banner_nanwei {
  background: url("/img/home/banner_nanwei.png") no-repeat;
  background-size: 100% 100%;
}
.top-banner {
  position: relative;
  width: 100%;
  height: 513px;
  .top-banner-btn {
    width: 112px;
    height: 32px;
    position: absolute;
    bottom: 56px;
    left: calc(50% - 56px);
  }
}
.about-wanfu-box {
  .about-wanfu-box-title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $primary-text;
    line-height: 25px;
    letter-spacing: 0.56px;
  }
  .about-wanfu-box-desc {
    font-size: 14px;
    color: $primary-text;
    line-height: 24px;
    margin: 16px 0;
    text-indent: 2em;
    word-break: break-all;
  }
  .aboutwf-video {
    position: relative;
    width: 100%;
    height: 200px;
    background: linear-gradient(270deg,#060e24, #000000);
    border-radius: 2px;
    .play-icon {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 94px;
      height: 94px;
      @include bgConfig;
      background-size: 100% 100%;
      cursor: pointer;
      transition: all 0.3s ease-out;
      background-image: url('../../public/img/home/icon_play@2x.png');
      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}
.solution-box {
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  .solution-box-item {
    border: 1px solid #d2d9dd;
    border-bottom: none;
    background: #fff;
    box-sizing: border-box;
    .solution-box-item-text {
      height: 56px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      text-align: left;
      color: #333333;
      line-height: 22px;
      padding: 0 20px;
      box-sizing: border-box;
      .solution-box-item-text-img {
        width: 10px;
      }
    }
    .solution-box-item-text-active {
      color: $primary-color;
      font-weight: 700;
    }
    .solution-box-item-children {
      width: 100%;
      border-top: 1px solid #d2d9dd;
      .solution-box-item-children-box {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #d2d9dd;
        .solution-box-item-children-title {
          font-size: 14px;
          font-weight: 700;
          color: $primary-text;
          line-height: 20px;
          @include text-ellipsis-line(1)
        }
        .solution-box-item-children-content {
          font-size: 12px;
          color: #999999;
          line-height: 20px;
          margin-top: 12px;
          @include text-ellipsis-line(2)
        }
      }
    }
    &:last-child {
      .solution-box-item-text {
        border-bottom: 1px solid #d2d9dd;
        box-sizing: border-box;
      }
      .solution-box-item-children {
        border: none;
      }
    }
  }
}
.products-box {
  .products-box-title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    line-height: 25px;
  }
  .products-box-sub-title {
    font-size: 12px;
    text-align: center;
    color: #929caa;
    line-height: 17px;
    margin-top: 8px;
  }
  .products-box-content {
    margin-top: 16px;
    background: #2a3d58;
    width: 100%;
    .products-box-content-item {
      position: relative;
      width: 100%;
      height: 56px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      line-height: 22px;
      .round {
        width: 6px;
        height: 6px;
        background: linear-gradient(180deg,#ffffff, #48aafb);
        border-radius: 50%;
        margin-right: 12px;
      }
      &:not(:last-child):before {
        position: absolute;
        bottom: 0;
        content: '';
        width: 295px;
        height: 1px;
        background: #54647b;
      }
    }
  }
}
.hardware-box {
  margin-top: 16px;
  .img-swiper {
    height: 420px !important;
  }
  .swiper-slide {
    background: #ffffff;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
  .hardware-box-card:last-child:before {
    background: transparent;
  }
  .hardware-box-card {
    height: 80px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      bottom: 1px;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background: #e7eaec;
    }
    .hardware-box-card-name {
      font-size: 14px;
      font-weight: 700;
      color: $primary-text;
      width: calc(100% - 90px);
    }
    .hardware-box-card-img {
      width: 90px;
      height: 75px;
      margin: 0 auto;
      @include bgConfig;
      background-size: contain;
      background-position: center;
    }
  }
}
.case-box {
  padding: 20px;
  box-sizing: border-box;
  .case-box-card {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.10);
    .case-box-card-title {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: $primary-text;
      line-height: 22px;
    }
    .case-box-card-content {
      word-break: break-all;
      font-size: 12px;
      color: $primary-text;
      line-height: 24px;
      margin-top: 18px;
      @include text-ellipsis-line(3)
    }
  }
  .case-box-card-0 {
    margin-top: 20px;
    background: url("/img/home/card-01.png") no-repeat;
    background-size: 100% 100%;
  }
  .case-box-card-1 {
    background: url("/img/home/card-02.png") no-repeat;
    background-size: 100% 100%;
  }
  .case-box-card-2 {
    background: url("/img/home/card-03.png") no-repeat;
    background-size: 100% 100%;
  }
}
.analysis-box {
  height: 195px;
  .analysis-box-content {
    width: 25%;
    text-align: center;
    margin-top: 36px;
    .analysis-box-content-num {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }
    .analysis-box-content-label {
      margin-top: 9px;
      font-size: 12px;
      color: #b7bfc9;
      line-height: 17px;
      letter-spacing: 0.5px;
    }
  }
}
</style>

<template>
  <div class="h5-box">
    <div class="contactWe-banner" />
<!--    联系方式-->
    <ModuleWrap
      class="contactWe-box"
      title="联系方式"
      bg-img="/img/contactWe/bg01.png"
    >
      <div
        class="contactWe-box-item"
        v-for="item in contactUser"
        :key="item.name"
      >
        <img class="contactWe-box-item-icon" :src="item.icon" alt="">
        <div class="contactWe-box-item-right">
          <div class="contactWe-box-item-region">{{item.region}}</div>
          <div class="contactWe-box-item-name">{{item.name}}</div>
        </div>
      </div>
    </ModuleWrap>
<!--    公司地址-->
    <ModuleWrap
      class="address-box"
      title="公司地址"
      bg-col="#f9fafb"
    >
      <div class="address-box-sub-title">
        <img src="/img/contactWe/address.png" alt="">
        <div>福州市闽侯县万福中心1号楼21层</div>
      </div>
      <div class="address-box-card-box">
        <div class="map-content" ref="map" id="container"></div>
      </div>
    </ModuleWrap>
  </div>
</template>

<script>
import {system} from "@/config/system_type";
import ModuleWrap from '../components/moduleWrap'
import {BMPGL} from '../util/bmpgl'
export default {
  name: "contactWe",
  components: {
    ModuleWrap
  },
  data() {
    return {
      contactUser: [
        {
          region: '福建区域',
          name: '范先生 15060692281',
          icon: '/img/contactWe/icon_phone01.png'
        },
        {
          region: '湖南区域',
          name: '段先生 18859196008',
          icon: '/img/contactWe/icon_phone01.png'
        },
        {
          region: '四川、重庆区域',
          name: '刘先生 18985186546',
          icon: '/img/contactWe/icon_phone01.png'
        },
        {
          region: '其他区域',
          name: '刘女士 18659195580',
          icon: '/img/contactWe/icon_phone01.png'
        },
        {
          region: '邮箱',
          name: 'lxudong2@linewell.com',
          icon: '/img/contactWe/icon_phone02.png'
        },
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  methods: {
    initMap() {
      // 传入密钥获取地图回调。
      BMPGL().then((BMapGL) => {
        let lng = 119.223231
        let lat = 26.046298
        // 创建地图实例
        let map = new BMapGL.Map("container");
        // 创建点坐标 axios => res 获取的初始化定位坐标
        const point = new BMapGL.Point(lng, lat);
        // 保存数据
        const marker = new BMapGL.Marker(point); // 创建点
        console.log(marker)
        const opts = {
          position: point, // 指定文本标注所在的地理位置
          offset: system === 'wanfu' && new BMapGL.Size(-110, -90) || system === 'nanwei' && new BMapGL.Size(-82, -90) // 设置文本偏移量
        };
        let label = null;
        if (system === 'wanfu') {
          label = new BMapGL.Label('<span style="position:relative;display: flex;align-items: center;">' +
          '<i style="position: absolute; border: 8px solid transparent;border-top: 8px solid #fff;bottom: -17px;left: 83px;"></i>' +
          '福建万福信息技术有限公司' +
          '</span>', opts);
        } else if (system === 'nanwei') {
          label = new BMapGL.Label('<span style="position:relative;display: flex;align-items: center;">' +
          '<i style="position: absolute; border: 8px solid transparent;border-top: 8px solid #fff;bottom: -17px;left: 55px;"></i>' +
          '南威集团万福业务群' +
          '</span>', opts);
        }
        // 自定义文本标注样式
        label.setStyle({
          color: '#0b68bf',
          fontWeight: 'bold',
          borderColor: '#fff',
          padding: '0 18px',
          boxShadow: '0 2px 12px 0 rgba(0,0,0,0.2)',
          fontSize: '14px',
          height: '40px',
          lineHeight: '40px',
          fontFamily: 'PingFang SC, PingFang SC-Medium',
        })
        map.addOverlay(label)
        map.centerAndZoom(point, 18)
        map.enableScrollWheelZoom(true)
        map.addOverlay(marker)
      })
      .catch((err)=>{
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/style.scss";
.contactWe-banner {
  width: 100%;
  height: 160px;
  background: url("/img/contactWe/banner05.png") no-repeat;
  background-size: 100% 100%;
}
.contactWe-box {
  .contactWe-box-item {
    margin-top: 16px;
    width: 100%;
    height: 72px;
    background: linear-gradient(225deg,#f8fbff, #ffffff);
    border: 2px solid #ffffff;
    border-radius: 4px;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    .contactWe-box-item-icon {
      width: 24px;
      height: 24px;
      margin: 12px 0 0 12px;
    }
    .contactWe-box-item-right {
      margin-left: 16px;
      .contactWe-box-item-region {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
      }
      .contactWe-box-item-name {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: $primary-text;
        line-height: 22px;
      }
    }
  }
}
.address-box {
  width: 100%;
  height: 362px;
  .address-box-sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
    margin-top: 16px;
    img {
      margin: 0 10px 4px 0;
      height: 18px;
    }
  }
  .address-box-card-box {
    width: 100%;
    height: 220px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 12px;
    padding: 12px;
    box-sizing: border-box;
    .map-content {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
